<template>
    <form @submit="(e) => handleClick(e)" class="template">
        <div class="title">
            <generalTitle :title="title" :breadcrumbs="breadcrumbs" :root="false" @goBack="goBack()" />
        </div>
        <div class="info-message" v-if="generalMessage">
            <InfoMessage :isActive="generalMessage" color="#C74D4D"
                :text="this.$t('general.infoMessage.generalError')" icon="error" />
        </div>

        <div :class="{ 'info-message': true, 'active': message.active, 'notActive': !message.active }">
            <InfoMessage :isActive="message.active" :color="message.icon === 'info' ? null : '#C74D4D'"
                :text="message.text" :icon="message.icon" />
        </div>
        <div class="loader-div">
            <Loader v-if="isLoading" />
        </div>
        <div v-if="!isLoading" class="data-box">

            <div class="flex">
                <label class="name">
                    <p v-if="isState"> {{ this.$t('caseFile.state.create-edit.inputTitles.name') }} </p>
                    <p v-else>{{ this.$t('categories.create-edit.inputTitles.name') }}</p>
                    <input :class="message.error && 'error-input'" :value="firstModelValue"
                        @input="updateFirstValue($event.target.value)" type="text" ref="nombreCategoria">
                    <p class="error" v-show="message.error">{{ this.$t('general.infoMessage.fillElement') }}</p>
                </label>
                <GeneralSelect v-if="isState" name="order" id="order" :options="optionOrder"
                    :optionSelected="orderValue" @change="this.$emit('updateOrderValue', this.orderValue)"
                    @optionChanged="orderValue = $event;" :label="this.$t('general.tableColumTitles.order')"
                    class="general-select"
                    :styleSelect="{ height: '48px', border: '1.25px solid var(--Gris--1-Dedavara, #B3BBBC)' }" />

                <label v-if="isState" class="color">
                    <p>{{ this.$t('caseFile.state.create-edit.inputTitles.color') }}</p>
                    <input type="color" @input="updatethreeModelValue($event.target.value)" :value="threeModelValue" />
                </label>

            </div>


            <label class="description">
                <p>{{ this.$t('categories.create-edit.inputTitles.description') }}</p>
                <textarea :value="secondModelValue" @input="updateSecondValue($event.target.value)"
                    type="text"></textarea>
            </label>

            <button :disabled="getError || isSpinnerBtn" :class="getError && 'disable'">
                <VueSpinnerToBtn ref="spinnerBtnAction">
                    {{ buttonName }}
                </VueSpinnerToBtn>
            </button>
        </div>
    </form>
</template>


<script>
import InfoMessage from "../general/InfoMessage.vue";
import generalTitle from "../general/generalTitle.vue";
import Loader from "../general/LoaderComponent.vue";
import VueSpinnerToBtn from "../general/VueSpinnerToBtn.vue";
import GeneralSelect from "@/components/forms/Select.vue";

export default {
    name: 'CreateEditCategory',
    components: { InfoMessage, generalTitle, Loader, VueSpinnerToBtn, GeneralSelect },
    data() {
        return { orderValue: `${this.orderState}`, optionOrder: [] }
    },
    watch: {
        orderState(newV) {
            this.orderValue = `${newV}`
        },
        totalState(newV) {
            let newArray = []
            for (let i = 1; i <= newV; i++) {
                newArray.push({ label: `${i}`, value: `${i}` })
            }
            this.optionOrder = newArray
        },
        focusado() {

            if (this.focusado) {
                this.$refs.nombreCategoria.focus();
                this.$emit('setFocusadoToFalse')
            }
        }
    },
    props: {
        // esta props debe tener -> active: booleano, text: "", icon: "" 
        message: {
            type: Object,
            require: true
        },
        generalMessage: {
            type: Boolean,
            default: false
        },
        // esto es para enlazar un input-model creado en el padre
        firstModelValue: {
            type: String,
            required: true
        },
        // esto es para enlazar un input-model creado en el padre
        secondModelValue: {
            type: String,
            required: true
        },
        isSpinnerBtn: {
            type: Boolean
        },
        threeModelValue: {
            type: String,
            required: false
        },
        onClickMethod: {
            type: Function,
            required: true
        },
        buttonName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        breadcrumbs: {
            type: Array,
            required: true
        },
        getError: {
            type: Boolean,
            required: false,

        },
        isLoading: {
            type: Boolean,
            required: true
        },
        isState: {
            type: Boolean,
            required: false,
            default: false
        },
        focusado: {
            type: Boolean,
            required: false,
            default: false
        },
        totalState: Number,
        orderState: Number
    },
    methods: {
        handleClick(e) {

            e.preventDefault();
            this.onClickMethod();

        },
        activeSpinner() {
            this.$refs.spinnerBtnAction.activeSpinner()
        },
        desactiveSpinner() {
            this.$refs.spinnerBtnAction.desactiveSpinner()
        },
        lastUrl() {
            window.history.back();
        },
        updateFirstValue(value) {
            this.$emit('update:firstModelValue', value);
        },
        updateSecondValue(value) {
            this.$emit('update:secondModelValue', value);
        },
        updatethreeModelValue(value) {
            this.$emit('update:threeModelValue', value);
        },
        goBack() {
            window.history.back();
        }
    }
}


</script>



<style scoped lang="scss">
.general-select {
    width: fit-content;
    margin-top: 24px;
}

.loader-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.error-input {
    border: 1px solid #C74D4D !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}

.disable {
    opacity: .3;
    cursor: not-allowed !important;
}

.active {
    opacity: 1;
}

.notActive {
    opacity: 0;
}

.info-message {
    text-align: left;
    max-width: 893px;
    margin-bottom: 18px;

    @media (max-width: 768px) {
        margin-left: 12px;
    }
}

.flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    grid-column: 1 / -1;

    gap: 16px;

    @media (max-width: 350px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;

        .color {
            margin-left: 24px !important;
        }
    }


    label {
        display: block;
    }


    .color {
        width: fit-content !important;

        input {
            background-color: transparent;
            padding: 4px !important;
            cursor: pointer;
        }

    }
}

.template {

    .title {
        display: flex;
        margin-top: 25px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            margin-left: 12px;
        }


        h3 {
            display: none;
            font-family: "Inter", sans-serif;
        }

        h1 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
            font-family: "Inter", sans-serif;
        }

        img {
            margin-top: 5px;
            margin-left: 24px;
            margin-right: 26px;
            width: 8px;
            height: 15px;
        }


    }




    .data-box {
        background-color: #FFFFFF;
        padding-top: 32px;
        margin-top: 0;
        max-width: 893px;
        height: 392px;
        border-radius: 8px;

        @media (max-width: 768px) {
            margin-left: 12px;
        }


        .description,
        .color {
            margin-top: 0px;
            margin: 24px 24px 0 24px;
        }

        .name {
            margin: 24px 0px 0 24px;
        }

        .color {
            margin: 24px 24px 0 0px;
        }


        label {
            display: flex;
            flex-direction: column;
            text-align: left;
            width: calc(100% - 48px);


            p {
                font-family: "Inter", sans-serif;
            }

            input {
                margin-top: 8px;
                border-radius: 8px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                padding-left: 13.78px;
                color: #0E0E0F;
                height: 42px;
                font-family: "Inter", sans-serif;
            }

            input::placeholder {
                color: #B3BBBC;
            }

            .error {
                color: #C74D4D;
                font-family: "Inter", sans-serif;
                padding-top: 10px;
            }



            textarea {
                margin-top: 8px;
                border-radius: 8px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                padding: 13.78px 14px;
                height: 129px;
                color: #0E0E0F;
                resize: vertical;
                font-family: "Inter", sans-serif;
                height: 163px;
                resize: none;
            }

            textarea::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #B3BBBC;
            }
        }

        button {
            width: calc(100% - 48px);
            margin: 24px;
            height: 48px;
            border-radius: 4px;
            border: none;
            background-color: #163F49;
            color: #F9FBFC;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: center;
            cursor: pointer;
            font-family: "Inter", sans-serif;
        }
    }
}




@media (min-width: 1200px) {


    .template {
        .title {
            flex-direction: column;
            margin-top: 32px;


            h3 {
                display: block;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;

                .h3 {
                    color: #A2ABAD;
                }

                margin: 0;

                span {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.52px;
                    text-align: left;
                    color: #365961;
                }
            }

            h1 {
                margin-top: 25px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
                color: #163F49;
            }

            img {
                display: none;
            }
        }

        .data-box {
            margin: 0 0 0 0;
            padding: 0;
            display: grid;
            grid-template-columns: 170px 1fr 218px;
            grid-template-rows: auto auto auto;
            border-radius: 8px;


            label {
                margin: 0;


                p {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;
                    color: #707C7E;
                }

                input {
                    height: 48px;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;

                }

            }

            button {
                margin: 0;
                width: 215px;
                height: 48px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                line-height: 21.78px;
                text-align: center;
                grid-column-start: 3;
                margin-top: 24px;
                margin-left: -20px;
                margin-bottom: 32px;
            }

            .name,
            .description {
                width: calc(100% - 48px);
                grid-column: span 3;
                margin-left: 24px;
                margin-top: 24px;
            }

            .description {
                textarea {
                    grid-column: span 3;
                    padding-top: 18px;
                    padding-left: 14px;
                    padding-right: 14px;

                    height: 126px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    text-align: left;
                    border-radius: 8px;
                    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                    color: #0E0E0F;
                }
            }
        }
    }


}
</style>