<template>


    <div class="users">
        <div v-if="headerActive" class="users__header">
            <div class="header__title">
                <generalTitle :divStyle="{ width: 'fit-content' }" :breadcrumbs="breadcrumbs" :title="title"
                    :root="true" @goBack="this.goBack()" />
            </div>
            <div class="header__buttons">
                <div class="new-manage">
                    <router-link v-if="secondaryButton" class="instructors-button" :to="secondaryButtonData.link">{{
                        secondaryButtonData.title }}</router-link>
                    <router-link v-if="!notCreateButton" class="new-user-button"
                        :class="secondaryButton ? 'width50' : null" :to="buttonTitle.linkToCreate">{{
                            buttonTitle.create
                        }}</router-link>
                    <InputDates v-if="date" />
                </div>
                <button class="delete-users" v-if="selectedElements.length > 0" @click="toggleModalMultiple">{{
                    buttonTitle.delete }}</button>
            </div>
        </div>

        <div v-show="!isLoading && search" class="search">
            <InputSearch :maxWidthNone="true" @search="this.handleSearch($event)" />
        </div>


        <div class="users-table">
            <div v-if="isLoading && !noLoader" class="loader">
                <LoaderComponent />
            </div>

            <slot name="secondTitle"></slot>


            <TableComponent :isEdit="isEdit" :isDelete="isDelete" v-show="!isLoading" :elementArray="users"
                :tableColumns="tableColumns" :isElementCreated="isElementCreated" :toggleModalSimple="toggleModalSimple"
                :editPath="editPath" @elementSelected="addSelectedElement" :tableInfoMessage="tableInfoMessage"
                :pendingRegistration="pendingRegistration" ref="tableComponent" @changeorder="changeorder"
                @addAllElements="addAllElements" :isImpersonal="isImpersonal" :nullValue="nullValue" />

            <slot name="footerTable"></slot>

            <Pagination v-show="!isLoading && paginationActive" :previousPage="this.pagination.previousPage"
                :currentPage="this.pagination.currentPage" :nextPage="this.pagination.nextPage"
                :lastPage="this.pagination.lastPage" @change-page="this.handleChangePage($event)"
                :usersLimit="parseInt(this.usersLimit)" @change-limit="this.usersLimit = $event" />
        </div>
    </div>

    <DeleteModalTable v-if="isModalActive" ref="DeleteModalTable" :selectedElements="selectedElements" :option="option"
        :lastSingularId="lastSingularId" @toggleModal="toggleModal"
        @eliminateSelectedCategories="eliminateSelectedCategories" @cleanSelectedCategories="cleanSelectedCategories"
        @trigger-fetch="getUsers" :path="this.path" :deleteModalText="deleteModalText"
        @checkboxToFalse="checkboxToFalse" />

</template>


<script>
import InputSearch from '../forms/InputSearch.vue';
import Pagination from '../forms/Pagination.vue';
import axios from 'axios';
import generalTitle from '@/components/general/generalTitle.vue';
import TableComponent from '../categories/TableComponent.vue';
import DeleteModalTable from '../categories/DeleteModalTable.vue';
import { mapGetters } from 'vuex';
import LoaderComponent from '../general/LoaderComponent.vue';
import InputDates from '../forms/InputDates.vue';
import { translationStateDefaultMixing, translationCategoryDefaultMixing } from "@/components/translationDefaultMixing.js"


export default {
    name: 'LayaoutTableDinamic',
    components: {
        InputSearch,
        Pagination,
        generalTitle,
        TableComponent,
        DeleteModalTable,
        LoaderComponent,
        InputDates
    },
    mixins: [translationStateDefaultMixing, translationCategoryDefaultMixing],
    props: {
        secondaryButtonData: {
            type: Object,
        },
        editPath: {
            type: String
        },
        search: {
            type: Boolean,
            default: true
        },
        isImpersonal: {
            type: Boolean,
            default: false
        },
        secondaryButton: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        breadcrumbs: {
            type: Array
        },
        pendingRegistration: {
            type: Boolean,
            default: false
        },
        path: {
            type: String,
            required: true
        },
        headerActive: {
            type: Boolean,
            default: true
        },
        buttonTitle: {
            type: Object,
            required: false
        },
        deleteModalText: {
            type: Object,
            required: false
        },
        tableInfoMessage: {
            type: Object,
            required: true
        },
        tableColumnsData: {
            type: Object,
            required: true
        },
        notCreateButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        checkBoxAvailable: {
            type: Boolean,
            default: true
        },
        optionMenuAvailable: {
            type: Boolean,
            default: true
        },
        date: {
            type: Boolean,
            default: false
        },
        changeRoleName: {
            type: Boolean,
            default: false
        },
        changeDate: {
            type: Boolean,
            default: false
        },
        stateBoldValue: {
            type: Boolean,
            default: false
        },
        mobileColSpanValue: {
            type: Number,
            default: 0
        },
        paginationActive: {
            type: Boolean,
            default: true
        },
        changePriority: {
            type: Boolean,
            default: false
        },
        keyPath: {
            type: String,
        },
        noLoader: {
            type: Boolean,
            required: false
        },
        lastActivityChange: {
            type: Boolean,
            default: false
        },
        nullValue: {
            type: String,
            default: ''
        },
        isEdit: {
            type: Boolean,
            default: true
        },
        isDelete: {
            type: Boolean,
            default: true
        },
        changeTypeName: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isLoading: false,
            tableColumns: {},
            isElementCreated: '',
            error: false,
            lastSingularId: '',
            option: '',
            selectedElements: [],
            isModalActive: false,

            //
            modalText: '',
            userSelected: [],
            users: [],
            pagination: {
                previousPage: 0,
                currentPage: 1,
                nextPage: 1,
                lastPage: 0
            },
            totalUsers: 0,
            usersLimit: 25,
            userSearch: '',
            orderAttr: 'id',
            order: 'DESC',

        }
    },
    created() {
        this.getUsers();
        this.isLoading = true

    },
    mounted() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },


    methods: {
        toggleModalMultiple() {
            this.option = 'multiple'
            this.toggleModal()
        },
        addSelectedElement(category) {
            if (this.selectedElements.includes(category)) {
                const index = this.selectedElements.indexOf(category);
                if (index !== -1) {
                    this.selectedElements.splice(index, 1);
                }
            } else {
                this.selectedElements.push(category);
            }

        },
        checkboxToFalse() {
            this.$refs.tableComponent.checkBoxToFalse()
        },
        toggleModal() {
            this.isModalActive = !this.isModalActive
        },
        eliminateSelectedCategories() {
            this.selectedElements = []
        },
        cleanSelectedCategories(e) {
            this.selectedElements = this.selectedElements.filter(category => category !== e)

        },
        addAllElements(categoriesArrayId) {
            this.selectedElements = categoriesArrayId
        },
        handleResize() {

            if (window.innerWidth > 768) {
                this.tableColumns = {
                    chekbox: this.checkBoxAvailable,
                    arrow: false,
                    columnTitle: this.tableColumnsData.desk.columnTitle,
                    options: this.optionMenuAvailable,
                    //Tiene que hacer match con los datos
                    mainRowName: this.tableColumnsData.desk.mainRowName,
                    titleDetailsRowName: this.tableColumnsData.desk.titleDetailsRowName,
                    //Tiene que hacer match con los datos
                    detailsRowName: this.tableColumnsData.desk.detailsRowName,
                    stateBold: this.stateBoldValue
                }
            } else {
                this.tableColumns = {
                    chekbox: this.checkBoxAvailable,
                    arrow: false,
                    columnTitle: this.tableColumnsData.mobile.columnTitle,
                    options: this.optionMenuAvailable,
                    //Tiene que hacer match con los datos
                    mainRowName: this.tableColumnsData.mobile.mainRowName,
                    titleDetailsRowName: this.tableColumnsData.mobile.titleDetailsRowName,
                    //Tiene que hacer match con los datos
                    detailsRowName: this.tableColumnsData.mobile.detailsRowName,
                    stateBold: this.stateBoldValue,
                    mobileColSpan: this.mobileColSpanValue

                }
            }

        },
        toggleModalSimple(e) {
            this.option = 'simple'
            this.lastSingularId = e
            this.toggleModal()
        },
        goToEditUser(e) {
            this.$router.push(`/users/edit-user/${e}`);
        },
        getUsers() {
            // Petición a la API para obtener los usuarios
            let url = ''
            if (this.keyPath === "caseFileAll") {
                url = `${this.path}?itemsPerPage=20&company=${this.userCompany}&exclude_subcasefile=false`

            }
            else if (this.path === '/case_files') {
                url = `${this.path}?itemsPerPage=${this.usersLimit}&page=${this.pagination.currentPage}&company=${this.userCompany}&exclude_subcasefile=true`
            } else if (this.path === '/forms') {
                url = `${this.path}?company=${this.userCompany}&itemsPerPage=${this.usersLimit}&page=${this.pagination.currentPage}&search=${this.userSearch}&order[${this.orderAttr}]=${this.order}`
            }
            else {
                //ponemos la de usuarios:
                url = `${this.path}?company=${this.userCompany}&itemsPerPage=${this.usersLimit}&page=${this.pagination.currentPage}&search=${this.userSearch}&order[${this.orderAttr}]=${this.order}`
            }



            axios.get(url)
                .then(response => {

                    this.users = response.data['hydra:member'].map(user => {
                        if (!user.id) {
                            return { ...user, id: user['@id'] }
                        } else {
                            return user
                        }
                    })
                    

                    this.totalUsers = response.data['hydra:view'];
                    //Se necesita el host para obtener la url completa
                    var host = window.location.protocol + "//" + window.location.host;
                    // Se obtienen los datos de la paginación
                    if (response.data['hydra:view']['hydra:last'] !== undefined) {
                        this.pagination.lastPage = Number((new URL(host + response.data['hydra:view']['hydra:last'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:next'] !== undefined) {
                        this.pagination.nextPage = Number((new URL(host + response.data['hydra:view']['hydra:next'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:previous'] !== undefined) {
                        this.pagination.previousPage = Number((new URL(host + response.data['hydra:view']['hydra:previous'])).searchParams.get('page'));
                    }
                    // Si la página actual es mayor que la última página, se establece la última página como página actual
                    if (this.pagination.currentPage > this.pagination.lastPage && this.pagination.lastPage > 0) {
                        this.pagination.currentPage = this.pagination.lastPage;
                    }
                    // En caso de busqueda exacta no devuelve la paginación
                    if (response.data['hydra:view']['hydra:last'] === undefined) {
                        this.pagination.currentPage = 1;
                        this.pagination.lastPage = 1;
                        this.pagination.nextPage = 0;
                    }

                })
                .then(() => {
                    //actualizar valores de roles y fechas
                    if (this.changeRoleName) {
                        this.users = this.changeRoleNameMethod(this.users)
                    }
                    if (this.changeDate) {
                        this.users = this.users.map(element => ({ ...element, createdAt: this.formatedDate(element.createdAt) }))
                    }
                    if (this.changeTypeName) {
                        this.users = this.users = this.changeTypeNameMethod(this.users)
                    }
                    if (this.lastActivityChange) {
                        this.users = this.users.map(element => ({ ...element, last_activity: this.formDateOnly(element.last_activity) }))
                    }
                    if (this.changePriority) {
                        this.users = this.changePriorityMethod(this.users)
                    }

                    

                    //actualizar traduccion de estados por defecto
                    if (this.keyPath === "caseFileAll") {
                        this.users = this.users.map(element => {
                            const states = this.changeTraslationDefaultState([element.state])
                            const categoryTranslate = this.changeTraslationDefaultCategories([element.category], false)
                          

                            return {
                                ...element,
                                state: states[0],
                                category: categoryTranslate[0]
                            }
                        })
                    }

                    if(this.path === '/forms') {
                        this.users = this.users.map(element => {
                            const categoryTranslate = this.changeTraslationDefaultCategories([element.category], false)
                            
                            return {
                                ...element,
                                category: categoryTranslate[0]
                            }
                        })
                    }

                })
                .catch(error => {

                    console.log(error);
                    this.users = [];
                    this.error = true;

                })
                .finally(() => {
                    this.isLoading = false
                })
        },
        handleChangePage(page) {
            this.pagination.currentPage = page;
            this.pagination.previousPage = page - 1;
            this.pagination.nextPage = page + 1;
        },
        handleSearch(search) {
            if (typeof search === 'string') {
                this.userSearch = search;
            }
        },
        changePriorityMethod(array) {
            return array.map(elemento => {
                let nuevoValor = ''
                switch (elemento.priority) {
                    case 1:
                        nuevoValor = this.$t('general.inputElements.priority.state.low');
                        break;
                    case 2:
                        nuevoValor = this.$t('general.inputElements.priority.state.medium')
                        break;
                    case 3:
                        nuevoValor = this.$t('general.inputElements.priority.state.hight')
                        break;
                }
                return { ...elemento, priority: nuevoValor }

            })
        },
        changeRoleNameMethod(array) {

            return array.map(elemento => {
                let nuevoValor = '';
                switch (elemento.userRole) {
                    case 'ROLE_COMPANY':
                        nuevoValor = this.$t('general.rolTypes.administrator');
                        break;
                    case 'ROLE_INSTRUCTOR':
                        nuevoValor = this.$t('general.rolTypes.instructor');
                        break;
                    case 'ROLE_SUPERVISOR':
                        nuevoValor = this.$t('general.rolTypes.supervisor');
                        break;
                    case 'ROLE_COMPANY_ADMIN':
                        nuevoValor = this.$t('general.rolTypes.administrator');
                        break;
                    case 'ROLE_USER':
                        nuevoValor = this.$t('general.rolTypes.auditor');
                        break;
                    case 'ROLE_INFORMANT':
                        nuevoValor = this.$t('general.rolTypes.informant');
                        break;


                }
                return { ...elemento, userRole: nuevoValor }
            })
        },
        changeTypeNameMethod(array) {
            return array.map(elemento => {
                let nuevoValor = '';
                switch (elemento.userType) {
                    case 'Administrador Empresa':
                        nuevoValor = this.$t('general.rolTypes.administrator');
                        break;
                    case 'Instructor':
                        nuevoValor = this.$t('general.rolTypes.instructor');
                        break;
                    case 'Supervisor':
                        nuevoValor = this.$t('general.rolTypes.supervisor');
                        break;
                    case 'ROLE_USER':
                        nuevoValor = this.$t('general.rolTypes.auditor');
                        break;
                    case 'ROLE_INFORMANT':
                        nuevoValor = this.$t('general.rolTypes.informant');
                        break;
                    case 'Auditor':
                        nuevoValor = this.$t('general.rolTypes.auditor');
                }
                return { ...elemento, userType: nuevoValor }
            })
        }
        ,
        changeorder(title) {

            let indexColumTh = null
            this.tableColumns.columnTitle = this.tableColumns.columnTitle.map((columTh, index) => {
                if (columTh.title === title) {
                    indexColumTh = index
                    return { ...columTh, isUp: !columTh.isUp }
                } else {
                    return { ...columTh, isUp: false }
                }
            })

            //para filtrar el orden según el boton seleccionado
            if (indexColumTh !== null) {
                this.orderAttr = this.tableColumns.mainRowName[indexColumTh]

            }

            this.order = this.order === 'ASC' ? 'DESC' : 'ASC';
            this.getUsers();
        },
        formatedDate(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Extraer componentes de la hora en la zona horaria local
            const horas = fecha.getHours().toString().padStart(2, '0'); // Hora en formato 24 horas
            const minutos = fecha.getMinutes().toString().padStart(2, '0'); // Minutos

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año} - ${horas}:${minutos}`;

            return fechaFormateada;
        },
        formDateOnly(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año}`;


            return fechaFormateada;
        },
        setorderAttr(order) {
            this.orderAttr = order;
            this.changeorder();
        },
        mapUsersToForms(users) {
            return users.map(user => ({
                id: user.id,
                form: user.name,
                modifiedDate: user.email,
                assigned: user.userType,
                pendiente: !user.verified
            }));

        }

    },
    computed: {
        ...mapGetters(['getUserCompany']),

        userCompany() {
            return this.getUserCompany;
        }
    },
    watch: {
        userCompany(newValue) {
            if (newValue === '') {
                //se ha hecho logout 
                return
            }
            this.getUsers()
        },
        usersLimit: {
            handler() {
                this.getUsers();
            }
        },
        'pagination.currentPage': function () {
            this.getUsers();
        },
        userSearch: {
            handler() {
                this.getUsers();
            }
        },
        users() {
            if (this.error === true) {
                this.isElementCreated = 'error'
                this.error = false
            }
            else if (this.users.length > 0) {
                this.isElementCreated = 'yes'
            } else if (this.users.length === 0 && this.userSearch === '') {
                this.isElementCreated = 'no'
            }
            else {
                this.isElementCreated = "notFound"
            }
        }
    }
}
</script>

<style scoped lang="scss">
.search {
    width: 100%;
    max-width: 408px;
}

.loader {
    display: flex;
    justify-content: center;
}

.users {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .users__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        gap: 24px;

        .header__title {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: fit-content;
        }

        .header__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 16px;

            .new-manage {
                display: flex;
                gap: 32px;

                .instructors-button {
                    border: 1px solid #163F49;
                    color: #163F49;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    width: 238px;
                    border-radius: 4px;
                    font-family: 'InterMedium';
                    cursor: pointer;
                }

                .new-user-button {
                    background: #163F49;
                    color: #F9FBFC;
                    font-family: 'InterMedium';
                    border-radius: 4px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 32px;
                    width: 238px;
                    cursor: pointer;
                }
            }

            .delete-users {
                background: #C74D4D;
                color: #F9FBFC;
                font-family: 'InterMedium';
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                border: none;
                justify-content: center;
                height: 32px;
                width: 238px;
                cursor: pointer;
            }
        }
    }

    .users-table {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .table {

            max-height: 560px;

            :deep(.table-container) {
                max-height: none;
            }
        }
    }

}


@media (max-width: 1200px) {
    .users {

        .users__header {
            align-items: flex-start;
            flex-direction: column;

            .header__buttons {
                gap: 32px;

            }

        }


    }
}

@media (max-width:900px) {
    .users {

        .users__header {
            align-items: flex-start;
            flex-direction: column;

            .header__buttons {
                gap: 18px;
                flex-wrap: nowrap;
                flex-direction: row;

                .new-manage {
                    gap: 18px;

                    .instructors-button {
                        width: fit-content;
                        padding: 5px 15px;
                    }

                    .new-user-button {
                        width: fit-content;
                        padding: 5px 15px;
                    }
                }

                .delete-users {
                    width: fit-content;
                    padding: 5px 15px;
                }
            }

        }


    }
}

@media (max-width:768px) {

    .search {
        max-width: 100%;
    }

    .users {
        .users__header {

            .header__title {
                width: 100%;
                max-width: 100%;
            }

            .header__buttons {
                width: 100%;
                flex-direction: column;

                .delete-users {
                    width: 100%;
                }

                .new-manage {
                    width: 100%;
                    gap: 12px;
                    justify-content: space-between;

                    .instructors-button {
                        font-size: 13px;
                        width: 50%;
                    }

                    .new-user-button {
                        font-size: 13px;
                        width: 100%;
                    }

                    .width50 {
                        width: 50%;
                    }
                }
            }
        }

        .users-table {
            .table {
                height: 500px;
                min-height: 500px;
            }
        }
    }
}


@media (max-width:410px) {
    .users {
        .users__header {
            .header__buttons {

                .new-manage {
                    flex-direction: column;

                    .instructors-button {
                        width: 100%;
                    }

                    .new-user-button {
                        width: 100%;
                    }

                    .width50 {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>