import { createI18n } from 'vue-i18n'
import traducciones from "../locales/traducciones.json"
import traslationEnglish from "../locales/translationEnglish.json"

let navigatorLanguage = navigator.language.split('-')[0];

 const i18n = createI18n({
    locale: localStorage.getItem('selectedLanguage') || navigatorLanguage || 'es',
    messages: {
        es: traducciones,
        en: traslationEnglish
      },
    silentTranslationWarn: true,
    fallbackWarn: false,
    
})

export default i18n;