<template>
    
    <TableMenuLayaout :tableOptions="tableOptions" :descriptionName="descriptionName" :dbUrl="dbUrl"
        :breadcrumbs="breadcrumbs" :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText" />
        
</template>

<script>
import TableMenuLayaout from "@/components/general/TableMenuLayaout.vue"

export default {
    name: 'CategoriesComponent',
    components: { TableMenuLayaout},
    data() {
        return {
            tableOptions: {
                chekbox: true,
                arrow: true,
                columnTitle: [{ title: this.$t('general.tableColumTitles.category'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.category'),  isUp: false  }],
                options: true,
                //Tiene que hacer match con los datos
                mainRowName: ['name'],
                titleDetailsRowName: [this.$t('general.tableColumTitles.description.title')],
                //Tiene que hacer match con los datos
                detailsRowName: ['description']
            },
            descriptionName: {
                title: this.$t('categories.showCategories.title'),
                buttonAddName: this.$t('categories.showCategories.button')
            },
            dbUrl: {
                path: "/categories",
                pathToCreate: "categorias/crear-categoria",
                pathToEdit: "categorias/editar-categoria"
            },
            breadcrumbs: [{ label: this.$t('categories.showCategories.breadcrumbs'), to: '/categorias' }],
            tableInfoMessage: {
                notCreate: this.$t('categories.showCategories.noCategories'),
                notSearch: this.$t('general.search.noCategory')
            },
            deleteModalText: {
                title:  this.$t('categories.showCategories.deleteModal.title'),
                namePlural: this.$t('categories.showCategories.deleteModal.plurarText'),
                textSingular: this.$t('categories.showCategories.deleteModal.textSingular'),
                errorSingular: this.$t('categories.showCategories.deleteModal.errorSingular'),
                errorPlural: this.$t('categories.showCategories.deleteModal.errorPlural')
            }
        }
    },
    
}

</script>