<template>
    <template v-if="!isInput">
        <p v-if="labelInclude?.active" :style="labelInclude?.style" class="labelAperance">{{ labelInclude?.name }}</p>
        <div class="pruebaPlace" :title="localSelectedValue.name" :style="selectDivContainer">
            <multiselect ref="multiselect" v-model="localSelectedValue" :options="optionArray"
                :multiple="isMultiple ? true : false"  :disabled="isDisabled"
                :placeholder="localSelectedValue.name ? localSelectedValue.name : placeholderText"
                :class="{ 'has-placeholder': localSelectedValue === '', 'error': error && (localSelectedValue === '' || localSelectedValue.length === 0) }"
                label="name" track-by="name" @select="updateSelectedValue" @remove="removeSelectedValue"
                :open-direction="'bottom'" select-label="" :clear-on-select="isMultiple ? false : true"
                :close-on-select="isMultiple ? false : true" :preserve-search="true" :max-height="130">
                <template #noResult>
                    <span class="no-result">{{ this.$t('general.search.noResult') }}</span>
                </template>
                <template #noOptions>
                    <span class="no-result">{{ this.$t('general.search.NoElement') }}</span>
                </template>
            </multiselect>

        </div>
        <p :style="styleErrorMessage" class="error-message" v-if="error && localSelectedValue === ''">{{ this.$t('general.infoMessage.fillElement')  }}
        </p>
    </template>

    <div v-else>
        <p v-if="labelInclude?.active" :style="labelInclude?.style" class="labelAperance">{{ labelInclude?.name }}</p>
        <input v-model="localSelectedValue" :id="labelIndentify" type="text"
            :class="{ 'singularInput': true, 'has-placeholder': localSelectedValue === '', 'error': error && localSelectedValue === '' }"
            :placeholder="placeholderText" @input="updateSelectedValue" />
        <p class="error-message" v-if="error && localSelectedValue === ''">{{ this.$t('general.infoMessage.fillElement')  }}</p>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'



export default {
    name: 'SelectSearchBox',
    components: { Multiselect },
    data() {
        return {
            localSelectedValue: this.selectedValue,
        };
    },
    props: {
        labelIndentify: String,
        placeholderText: String,
        optionArray: Array,
        selectedValue: [String, Object],
        error: Boolean,
        fileDataName: String,
        selectDivContainer: Object,
        isInput: {
            type: Boolean,
            default: false
        },
        isMultiple: {
            type: Boolean,
            default: false,
            required: false
        },
        styleErrorMessage: Object,
        labelInclude: {
            type: Object,
            required: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateSelectedValue() {
            this.$emit('update:selectedValue', this.localSelectedValue);
        },
        removeSelectedValue() {
            if (this.isMultiple) {
                this.$emit('update:selectedValue', this.localSelectedValue);
                return
            }
            this.$emit('update:selectedValue', '');
        },
    },
    emits: ['cleanFileDataError', 'update:selectedValue'],
    watch: {
        selectedValue() {
            this.localSelectedValue = this.selectedValue
            if (this.error && this.localSelectedValue.name !== '') {
                this.$emit('cleanFileDataError', this.fileDataName);
            }
        }
    }

}
</script>


<style src="@/../node_modules/vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped lang="scss">
.error-message {
    text-align: left;
    color: #C74D4D;
    margin-top: 6px;
    font-size: 14px;
    font-family: 'InterRegular';
}

.pruebaPlace {
    width: 100%;

    :deep(.multiselect__tag) {
        background: #163F49;
    }
}

.singularInput {
    min-height: 48px;
    border-radius: 8px;
    border: 1.25px solid #B3BBBC;
    padding-inline: 14px;


}

.labelAperance {
    text-align: left;
    margin-bottom: 8px;
    font-size: 14px;
    color: #707C7E;
    font-family: 'InterMedium', sans-serif;
}


input,
.multiselect {
    width: 100%;
    font-size: 14px;
    color: #0E0E0F;
    font-family: 'InterRegular';
    line-height: 16.94px;
    cursor: pointer;
}

input::placeholder {
    color: #B3BBBC;
}
</style>


<style lang="scss">
.error {
    .multiselect__tags {
        border: 1.25px solid #C74D4D !important
    }
}

.multiselect {
    width: 100%;
    font-size: 14px;
    color: #0E0E0F;
    font-family: 'InterRegular';
    line-height: 16.94px;
    cursor: pointer;
}

.multiselect__tags {
    border: 1.25px solid #B3BBBC !important;
    padding-top: 15px !important;
    padding-bottom: 7px !important;
    border-radius: 8px;
    min-height: 48px;

}

.multiselect__tags.multiselect__tags-wrap .multiselect__tag {
    background-color: red !important;
}

.multiselect__select {
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: right 10px center;

    margin-top: 6px;
    margin-right: 5px;
    padding-left: 6px;

}

.multiselect--disabled .multiselect__select {
  display: none !important; /* Esto ocultará el botón de desplegable cuando el componente esté deshabilitado */
}

.multiselect__content-wrapper {
    min-height: 69px;
}

.multiselect__option--highlight {
    background-color: #CBDEE2;
    color: #0E0E0F;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__option--selected.multiselect__option--highlight {

    color: #fff;
    background: #163F49;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__option--selected::after {
    content: '';
    color: #fff;
    background: #163F49;

}

.multiselect__option--selected {
    background: #163F49;
    color: #fff;
    font-weight: 400;
    font-family: 'InterRegular';
    font-size: 14px;
}

.multiselect__single {
    max-width: 100%;
    /* Ancho máximo para el select */
    overflow: hidden;
    /* Ocultar el desbordamiento */
    white-space: nowrap;
    /* Evitar que el texto se divida en varias líneas */
    text-overflow: ellipsis;
}


//LA X para eliminar
.multiselect__option--selected.multiselect__option--highlight::after {
    content: "";
    background: transparent;
    color: #fff;
    width: 10%;
    height: 100%;
    background-image: url('../../assets/icons/whiteClose.svg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 50% 50%;
}

.multiselect__placeholder {

    padding-top: 0 !important;
    color: #B3BBBC;
    padding-left: 6px !important;

}

.multiselect__select::before {
    display: none !important;
}
</style>