<template>
    <div class="template" @dragover="inPreviewForm = false">
        <div ref="infoMessageHeader" class="title">
            <generalTitle
                :title="isEdit ? this.$t('complainForm.create-edit.titleEdit') : this.$t('complainForm.create-edit.titleCreate')"
                :breadcrumbs="isEdit ? breadcrumbsEdit : breadcrumbs" @goBack="this.goBack()" :root="false" />
            <div class="message error-form">
                <InfoMessage :styleDiv="{ marginBottom: 0 }" :text="getErrorMessage.message"
                    :isActive="getErrorMessage.active" :icon="getErrorMessage.icon" :color="getErrorMessage.color" />
            </div>
        </div>

        <div v-if="isLoader" class="loader">
            <LoaderComponent />
        </div>


        <div v-if="!isLoader" class="safe-form-box">
            <button :disabled="isDisabled" :class="isDisabled && 'isDisabled'" class="safe-form"
                @click="handleButtonAction">
                <VueSpinnerToBtn ref="spinnerBtn">{{ this.$t('complainForm.create-edit.saveButton') }}</VueSpinnerToBtn>
            </button>
            <div>
                <label>
                    {{ this.$t('complainForm.create-edit.formNameInput.title') }}
                    <input :style="isFornNameBlank && { borderColor: '#C74D4D' }" type="text"
                        :placeholder="this.$t('complainForm.create-edit.formNameInput.placehodler')"
                        v-model="this.formName">
                </label>
                <div class="selectBox">
                    <p>{{ this.$t('general.inputElements.complainType.title') }}</p>
                    <div>
                        <SelectSearchBox v-model:selectedValue="this.formCategory" :optionArray="this.categories"
                            labelIndentify="denuncia"
                            :placeholderText="this.$t('general.inputElements.complainType.placehodler')"
                            fileDataName="denuncia" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isLoader" class="preview-form" @dragover.stop="inPreviewForm = true">
            <h2>{{ this.$t('complainForm.create-edit.previewElement.title') }}</h2>
            <p v-if="inputs.length > 0">{{ this.$t('complainForm.create-edit.previewElement.info') }}</p>


            <!-- Para mover los elementos de la preview-->
            <VueDraggable v-model="inputs">
                <InputsPreviewForm v-for="(input, index) in inputs" :key="input.id" :name="input.name" :id="input.id"
                    :type="input.type" :options="input.options" @removeInput="removeInput(index)"
                    :isRequired="input.isRequired" @openModalEdit="openModalEdit" />
            </VueDraggable>

            <button class="add-field" @click="openModal">
                <img src="@/assets/icons/add.svg" :alt="this.$t('general.alt.add')"> {{
                    this.$t('complainForm.create-edit.mobileButton') }}
            </button>
        </div>

        <transition name="slide-up">
            <div v-if="!isLoader" ref="overflow" class="overflow">
                <div class="inputs-movile" v-if="this.isInputsOpen">
                    <img @click="closeModal" src="@/assets/icons/close.svg" :alt="this.$t('general.alt.close')">
                    <h2 ref="infoMessageGeneral">{{ inputTypeText }}</h2>
                    <div class="message error-mobile">
                        <InfoMessage :text="errorMessage" :isActive="isError" icon="error" color="#C74D4D" />

                        <InfoMessage v-show="selectedInputType === 'verification'" :text="verification.errorText"
                            :isActive="verification.error" icon="error" color="#C74D4D" />
                    </div>

                    <label v-if="selectedInputType !== 'verification'">
                        {{ inputLabelName }}
                        <input type="text" v-model.trim="inputName" name="" id="" :placeholder="placeholderName">
                    </label>

                    <!-- selector de opciones -->
                    <div v-if="selectedInputType === 'sel'">
                        <label>
                            {{ this.$t('complainForm.create-edit.addElements.select.options.title') }}
                            <div class="options-select">
                                <OptionsSelect ref="optionsSelect" @optionsChanged="updateOptions" />
                            </div>
                        </label>
                    </div>

                    <!--casilla de verificación-->
                    <div v-if="selectedInputType === 'verification'" class="verification-label">
                        <div class="verification-description">
                            <label @click="this.$refs.textInput.focus()">{{
                                this.$t('complainForm.create-edit.addElements.verification.title') }}</label>
                            <div class="button-link-container">
                                <div class="flex">
                                    <button @click="eliminateLink"
                                        :title="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"><img
                                            src="@/assets/icons/linkOff.svg"
                                            :alt="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"></button>
                                    <button
                                        :title="this.$t('complainForm.create-edit.addElements.verification.addLink')"
                                        @click="addLink"><img src="@/assets/icons/link.svg"
                                            :alt="this.$t('complainForm.create-edit.addElements.verification.addLink')"></button>
                                </div>

                                <input type="text" v-model="linkHref"
                                    :placeholder="this.$t('complainForm.create-edit.addElements.verification.placeholderLink')">
                            </div>
                        </div>
                        <div @click="this.$refs.textInput.focus()"
                            :style="isFocusable ? { border: '2px solid #0E0E0F' } : null" class="verification">
                            <div @keydown="prevenirEnter" :class="!verification.value && 'placeholderClass'"
                                @focus="isFocusable = true" @blur="isFocusable = false" @paste="handlePaste"
                                contenteditable="true" @input="updateValue" @mousedown="isVerificationMouseDown = true"
                                ref="textInput">
                            </div>
                        </div>

                    </div>



                    <div class="checkbox-add">
                        <div class="checkbox">
                            <Checkbox v-model="mobileCheckbox" />
                            <p>{{ this.$t('complainForm.create-edit.addElements.required') }}</p>
                        </div>
                        <button class="add-input" @click="addInput">{{
                            this.$t('complainForm.create-edit.addElements.button') }}</button>
                    </div>



                    <select name="" id="" v-model="selectedInputType">
                        <option value="sel">{{ this.$t('complainForm.create-edit.addElements.mobileText.addSelector') }}
                        </option>
                        <option value="inp">{{ this.$t('complainForm.create-edit.addElements.mobileText.addInput') }}
                        </option>
                        <option value="textarea">{{
                            this.$t('complainForm.create-edit.addElements.mobileText.addTextarea') }}</option>
                        <!--  <option value="fil">Añadir Input File</option>-->
                        <option value="verification">{{
                            this.$t('complainForm.create-edit.addElements.mobileText.addVerification') }}</option>
                    </select>
                </div>
            </div>
        </transition>

        <div v-if="!isLoader" class="inputs-desktop">
            <NewInputs ref="newInputs" @inputAdded="addInputText" @selectAdded="addSelect"
                @textCloned="handleTextCloned" @fileCloned="handleFileCloned" @selectCloned="handleSelectCloned" />
        </div>

        <!-- MODAL de editar el input seleccionado-->
        <FormEditModal v-if="isEditModalActive" @editFormElement="editFormElement" @toggleModalEdit="toggleModalEdit"
            :options="actualEditInput.options" :name="actualEditInput.name" :type="actualEditInput.type"
            :id="actualEditInput.id" :isRequired="actualEditInput.isRequired" />

    </div>
</template>


<script>
import InputsPreviewForm from '@/components/formulario_denuncia/InputsPreviewForm.vue';
import Checkbox from '../formulario_denuncia/Checkbox.vue';
import NewInputs from '../formulario_denuncia/NewInputs.vue';
import { VueDraggable } from 'vue-draggable-plus'
import InfoMessage from '../general/InfoMessage.vue';
import { nanoid } from 'nanoid';
import FormEditModal from '../formulario_denuncia/FormEditModal.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import generalTitle from '../general/generalTitle.vue';
import LoaderComponent from '../general/LoaderComponent.vue';
import verificationMixing from '../formulario_denuncia/verificationMixing';
import SelectSearchBox from '../forms/SelectSearchBox.vue';
import OptionsSelect from '../formulario_denuncia/OptionsSelect.vue';
import VueSpinnerToBtn from '../general/VueSpinnerToBtn.vue';
import { mapMutations } from 'vuex';
import {translationCategoryDefaultMixing} from "@/components/translationDefaultMixing.js"

export default {
    name: 'NewComplaintFormComponent',
    components: {
        InputsPreviewForm,
        Checkbox,
        NewInputs,
        VueDraggable,
        InfoMessage,
        FormEditModal,
        generalTitle,
        LoaderComponent,
        SelectSearchBox,
        OptionsSelect,
        VueSpinnerToBtn
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
        this.getCategories()
        if (this.$route.params.id !== undefined) {
            this.toggleUserCompanyDisable()
            const id = this.$route.params.id
            this.isEdit = true
            this.editId = id
            this.breadcrumbsEdit = [{ label: this.$t('complainForm.create-edit.breadcrumbs.first'), to: '/complaint-forms' }, { label: this.$t('complainForm.create-edit.breadcrumbs.second-edit'), to: `/complaint-forms/edit-form/${this.editId}` }]
            this.getPreviewFormData(id)
        }
    },
    beforeUnmount() {
        if (this.isEdit) {
            this.toggleUserCompanyDisable()
        }
        document.removeEventListener("click", this.handleClickOutside);
        document.body.style.overflow = 'auto';
    },
    watch: {
        userCompany(newValue) {
            if (newValue === '') {
                //se ha hecho logout 
                return
            }
            this.getCategories()
        },
        formName(newValue) {
            if (!this.isFornNameBlank) return
            if (newValue !== '') {
                this.isFornNameBlank = false
                this.getErrorMessage.active = false
            }
        }

    },
    mixins: [verificationMixing, translationCategoryDefaultMixing],
    data() {
        return {
            isDisabled: false,
            selectName: '',
            editId: '',
            isEdit: false,
            isFornNameBlank: false,
            isMobile: true,
            isFocusable: false,
            isVerificationMouseDown: false,
            linkHref: '',
            verification: {
                value: '',
                error: false,
                linkButton: false,
                errorText: ''
            },
            isLoader: true,
            breadcrumbs: [{ label: this.$t('complainForm.create-edit.breadcrumbs.first'), to: '/complaint-forms' }, { label: this.$t('complainForm.create-edit.breadcrumbs.second-create'), to: '/complaint-forms/create-form' }],
            breadcrumbsEdit: [],
            getErrorMessage: {
                active: false,
                message: '',
                icon: 'error',
                color: '#C74D4D'
            },
            categories: [],
            isEditModalActive: false,
            actualEditInput: {
                name: 'pepi',
                id: '123',
                type: 'input',
                isRequired: false,
                options: ''
            },
            prueba: nanoid(),
            mobileCheckbox: false,
            count: 0,
            errorMessage: '',
            inPreviewForm: false,
            isInputsOpen: false,
            selectedInputType: 'sel',
            inputName: '',
            isError: false,
            inputs: [],
            inputValue: [],
            inputTypes: {
                sel: {
                    text: this.$t('complainForm.create-edit.addElements.select.headerTitle'),
                    labelName: this.$t('complainForm.create-edit.addElements.select.title'),
                    placeholderName: this.$t('complainForm.create-edit.addElements.generalPlaceholder'),
                    actualType: 'Selector'
                },
                inp: {
                    text: this.$t('complainForm.create-edit.addElements.input.headerTitle'),
                    labelName: this.$t('complainForm.create-edit.addElements.input.title'),
                    placeholderName: this.$t('complainForm.create-edit.addElements.generalPlaceholder'),
                    actualType: 'Input'
                },
                /*
                fil: {
                    text: 'Nuevo Input File',
                    labelName: 'Nombre de su archivo',
                    placeholderName: 'Describa su inputfile',
                    actualType: 'Input File'
                },
                */
                textarea: {
                    text: this.$t('complainForm.create-edit.addElements.textarea.headerTitle'),
                    labelName: this.$t('complainForm.create-edit.addElements.textarea.title'),
                    placeholderName: this.$t('complainForm.create-edit.addElements.generalPlaceholder'),
                    actualType: 'Textarea'
                },
                verification: {
                    text: this.$t('complainForm.create-edit.addElements.verification.headerTitle'),
                    labelName: this.$t('complainForm.create-edit.addElements.verification.title'),
                    placeholderName: this.$t('complainForm.create-edit.addElements.generalPlaceholder'),
                    actualType: 'Verificación'
                }

            },
            formName: '',
            formCategory: '',
        }
    },
    computed: {
        inputTypeText() {
            return this.inputTypes[this.selectedInputType].text;
        },
        inputLabelName() {
            return this.inputTypes[this.selectedInputType].labelName;
        },
        inputLabelOptions() {
            return this.inputTypes[this.selectedInputType].labelOptions;
        },
        placeholderName() {
            return this.inputTypes[this.selectedInputType].placeholderName;
        },
        placeholderOptions() {
            return this.inputTypes[this.selectedInputType].placeholderOptions;
        },
        ...mapGetters(['getUserCompany']),
        userCompany() {
            return this.getUserCompany;
        }
    },
    methods: {
        ...mapMutations(['toggleUserCompanyDisable']),
        handleButtonAction() {
            this.isDisabled = true
            this.isEdit ? this.editForm(this.editId) : this.saveForm()
        },
        getPreviewFormData(id) {
            axios.get(`/forms/${id}`)
                .then(res => {

                    this.inputs = res.data.items
                    this.formName = res.data.title
                    if (res.data.category) {
                        const formCategorySelected = this.changeTraslationDefaultCategories([res.data.category], false)[0]
                        this.formCategory = { name: formCategorySelected.name, id: formCategorySelected.id }
                    }

                })
                .catch(error => {
                    console.log(error)
                    this.getErrorMessage.message = this.$t('general.infoMessage.generalError')
                    this.getErrorMessage.active = true
                    this.getErrorMessage.icon = 'error'
                    this.getErrorMessage.color = '#C74D4D'
                })
        },
        getCategories() {
            let url = `/categories?itemsPerPage=200&company=${this.userCompany}&exists[form]=false`

            axios.get(url)
                .then(response => this.categories = this.changeTraslationDefaultCategories(response.data["hydra:member"]) )
                .catch(error => {
                    console.log(error)
                    this.getErrorMessage.message = this.$t('general.infoMessage.GetError')
                    this.getErrorMessage.active = true

                })
                .finally(() => {
                    this.isLoader = false
                })
        }
        ,
        toggleModalEdit() {
            this.isEditModalActive = !this.isEditModalActive
        },
        editFormElement(values) {
            this.inputs = this.inputs.map(input => input.id === values.id ? values : input)
            this.toggleModalEdit()
        },
        openModalEdit(value) {
            //pasamos los datos de editat y abrimos el modal
            this.actualEditInput = value
            this.toggleModalEdit()
        },
        openModal() {
            this.isInputsOpen = true
            document.body.style.overflow = 'hidden';
        },
        closeModal() {
            this.isInputsOpen = false
            this.count = 0
            document.body.style.overflow = 'auto';
        },
        handleClickOutside(event) {
            if (this.isInputsOpen && !this.$refs.overflow.contains(event.target) && !event.target.closest('.remove')) {
                if (this.count > 0) {
                    this.isInputsOpen = false
                    this.count = 0
                    document.body.style.overflow = 'auto';
                } else {
                    this.count++
                }

            }

        },
        scrollToInfoMessage() {
            const element = this.$refs.infoMessageGeneral;
            element.scrollIntoView({ behavior: "smooth" });
        },
        scrollMessageHeader() {
            const element = this.$refs.infoMessageHeader;
            this.$nextTick(() => {
                // Aseguramos que el DOM se haya actualizado
                element.scrollIntoView({ behavior: "smooth" });
            });
        },

        addInput() {
            this.isError = false
            if (this.selectedInputType === 'verification') {

                if (this.$refs.textInput.textContent.trim() === '') {
                    this.verification.error = true
                    this.verification.errorText = this.$t('complainForm.create-edit.addElements.error')
                    this.scrollToInfoMessage()
                    setTimeout(() => {
                        this.verification.error = false
                    }, 2000)
                    return
                }



                const sanetizeValue = this.sanitizeHTMLOnlyAllowA(this.$refs.textInput.innerHTML)

                this.inputs.push({
                    id: nanoid(),
                    name: sanetizeValue,
                    type: 'Verficación',
                    isRequired: this.mobileCheckbox
                })
                this.mobileCheckbox = false

                this.verification.value = ''
                const editableDiv = this.$refs.textInput
                editableDiv.textContent = ''
            }
            else {
                if (this.inputName === '') {
                    this.isError = true
                    this.errorMessage = this.$t('complainForm.create-edit.addElements.error')
                    this.scrollToInfoMessage()
                    setTimeout(() => {
                        if (this.errorMessage !== this.$t('complainForm.create-edit.addElements.error')) return
                        this.isError = false
                    }, 2000)
                    return
                }



                if (this.selectedInputType !== 'sel') {
                    this.inputs.push({
                        id: nanoid(),
                        name: this.inputName,
                        type: this.inputTypes[this.selectedInputType].actualType,
                        isRequired: this.mobileCheckbox
                    })
                    this.mobileCheckbox = false
                }
                else {


                    const opt = this.inputValue.map(option => option.value).filter(option => option !== '')

                    if (opt.length === 0) {
                        this.isError = true
                        this.errorMessage = this.$t('complainForm.create-edit.addElements.select.options.error')
                        this.scrollToInfoMessage()
                        setTimeout(() => {
                            this.isError = false

                        }, 2000)
                        return
                    }

                    this.addSelect(opt, this.inputName, this.mobileCheckbox)
                    this.$refs.optionsSelect.cleanOption()
                    this.mobileCheckbox = false
                    this.inputValue = []
                }
                this.inputName = ''
            }
        },
        updateOptions(options) {
            this.inputValue = options
        },
        addInputText(input) {

            this.inputs.push({
                id: nanoid(),
                name: input.value,
                type: input.type,
                isRequired: input.isRequired,
            });

            //para poner el checkbox en falso, necesitamos que el vModelValue se llame igual que el dato del checkbox que cambiamos
            this.$refs.newInputs.setCheckBoxToFalse(input.vModelName)
        },

        addSelect(options, selectName, isRequired) {
            this.inputs.push({
                id: nanoid(),
                name: selectName,
                type: 'Selector',
                options: options,
                isRequired
            });

            //poner el checkbox en falso
            this.$refs.newInputs.setCheckBoxToFalse('select')
        },
        removeInput(index) {
            this.inputs.splice(index, 1);
        },
        handleTextCloned(textName) {
            if (this.inPreviewForm) {
                this.inputs.push({
                    id: nanoid(),
                    name: textName,
                    type: 'Input',
                });
            }
        },
        handleFileCloned(fileName) {
            if (this.inPreviewForm) {
                this.inputs.push({
                    id: nanoid(),
                    name: fileName,
                    type: 'Input File',
                });
            }
        },
        handleSelectCloned(option, selectName) {
            if (this.inPreviewForm) {
                this.inputs.push({
                    id: nanoid(),
                    name: selectName,
                    type: 'Selector',
                    options: option
                });
            }
        },
        goBack() {
            window.history.back();
        },
        isPreviewInput() {

            if (this.inputs.length === 0) {
                this.getErrorMessage.message = this.$t('complainForm.create-edit.saveWithoutDataError')
                this.getErrorMessage.active = true
                this.getErrorMessage.icon = 'error'
                this.getErrorMessage.color = '#C74D4D'
                this.scrollMessageHeader()
                return false
            }
            return true
        },
        editForm(id) {
            this.getErrorMessage.active = false
            if (this.formName === '') {
                this.getErrorMessage.message = this.$t('complainForm.create-edit.formNameInput.error')
                this.getErrorMessage.active = true
                this.getErrorMessage.icon = 'error'
                this.getErrorMessage.color = '#C74D4D'
                this.isFornNameBlank = true
                this.scrollMessageHeader()
                setTimeout(() => {
                    this.getErrorMessage.active = false
                }, 3000)
                return
            }
            if (!this.isPreviewInput()) return



            let dataPath = {
                items: this.inputs,
                title: this.formName,
            }
            if (this.formCategory) {
                dataPath.category = `/api/v1/categories/${this.formCategory.id}`
            }

            axios.patch(`/forms/${id}`, dataPath)
                .then(() => {
                    this.getErrorMessage.message = this.$t('general.infoMessage.editCorrectly')
                    this.getErrorMessage.active = true
                    this.getErrorMessage.icon = 'info'
                    this.getErrorMessage.color = ''
                    this.getCategories()
                    this.scrollMessageHeader()
                    setTimeout(() => {
                        this.getErrorMessage.active = false
                    }, 3000)
                })
                .catch(error => {
                    this.getErrorMessage.message = this.$t('general.infoMessage.generalError')
                    this.getErrorMessage.active = true
                    this.getErrorMessage.icon = 'error'
                    this.getErrorMessage.color = '#C74D4D'
                    console.log(error)
                    this.scrollMessageHeader()

                    setTimeout(() => {
                        this.getErrorMessage.active = false
                    }, 3000)
                })
                .finally(() => {
                    this.isDisabled = false
                    this.$refs.spinnerBtn.desactiveSpinner()
                })
        },
        async saveForm() {
            this.$refs.spinnerBtn.activeSpinner()
            this.getErrorMessage.active = false
            this.isFornNameBlank = false

            try {
                if (this.formName === '') {
                    this.getErrorMessage.message = this.$t('complainForm.create-edit.formNameInput.error')
                    this.getErrorMessage.active = true
                    this.getErrorMessage.icon = 'error'
                    this.getErrorMessage.color = '#C74D4D'
                    this.isFornNameBlank = true
                    this.scrollMessageHeader()
                    return
                }
                if (!this.isPreviewInput()) return

                var data = {
                    title: this.formName,
                    items: this.inputs,
                    company: `/api/v1/companies/${this.userCompany}`,
                };

                if (this.formCategory.id !== undefined) {
                    data.category = `/api/v1/categories/${this.formCategory.id}`
                }

                await axios.post(`/forms`, data);
                this.getErrorMessage.message = this.$t('general.infoMessage.saveCorrectly')
                this.getErrorMessage.active = true
                this.getErrorMessage.icon = 'info'
                this.getErrorMessage.color = ''
                this.inputs = []
                this.formName = '',
                    this.formCategory = ''
                this.getCategories()
                this.$store.commit('setChangeFormToggle');
                this.scrollMessageHeader()
                setTimeout(() => {
                    this.getErrorMessage.active = false
                }, 3000)

            } catch (error) {
                this.getErrorMessage.message = this.$t('general.infoMessage.generalError')
                this.getErrorMessage.active = true
                this.getErrorMessage.icon = 'error'
                this.getErrorMessage.color = '#C74D4D'
                this.scrollMessageHeader()
                console.log(error)

                setTimeout(() => {
                    this.getErrorMessage.active = false
                }, 3000)
            }
            finally {
                this.isDisabled = false
                this.$refs.spinnerBtn.desactiveSpinner()
            }
        },
    }

}
</script>

<style scoped lang="scss">
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 14px) center;
}



.error-mobile {
    margin-top: 10px;
    margin-inline: 36px;
}

.error-form {
    margin-bottom: 20px;
    margin-top: 0;
}


.button-link-container {
    position: absolute;
    top: calc(-155%);
    right: 0;
    background: white;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    width: 150px;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    border-radius: 3px;
    padding: 1px;

    @media (max-width: 550px) {
        top: 35px;

    }

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        img {
            height: 20px;
            width: 20px;
        }
    }

    input {
        border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
        color: #0E0E0F;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        padding: 2px;
        font-size: 10px;
        height: 30px;

    }

    input::placeholder {
        text-align: left;
        color: #B3BBBC;
    }
}

.flex {
    display: flex;
    justify-content: space-around;
}

.verification-label {
    margin-inline: 36px;
    display: grid;
    grid-template-columns: 1fr;
    width: calc(100% - 72px);


}

.verification-description {
    position: relative;

    @media (max-width: 550px) {
        margin-bottom: 70px;
    }

    label {
        margin-left: 0 !important;
    }
}

.placeholderClass::before {
    content: 'Texto';
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #B3BBBC;
}

.verification {
    cursor: text;

    margin-top: 8px;


    /* Ajusta el padding para que se parezca más a un input */
    display: grid;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    border-radius: 8px;

    /* Esto incluye el padding y el borde en el ancho total */

    &>div {
        margin-inline: auto;
        font-size: 14px;
        text-align: left;
        font-weight: 400;
        color: #0E0E0F;
        font-family: "Inter", sans-serif;

        padding: 17px 14px;
        width: 95%;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }



}

.loader {
    margin-inline: auto;
    grid-column: 1 / -1;
}



.template {

    @media (max-width: 300px) {
        width: calc(100% - 10px);
    }

    // css para drag and drop


    .fade-move,
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
        transform: scaleY(0.01) translate(30px, 0);
    }

    .fade-leave-active {
        position: absolute;
    }

    // css para la animacion de slide up

    .inputs-desktop {
        display: none;
    }

    .slide-up-enter-active,
    .slide-up-leave-active {
        transition: all .5s ease;
    }

    .slide-up-enter-from,
    .slide-up-leave-to {
        transform: translateY(100%);
    }

    .slide-up-enter-to,
    .slide-up-leave-from {
        transform: translateY(0);
    }

    .title {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .safe-form-box {
        padding: 24px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border-radius: 4px;

        .isDisabled {
            cursor: wait !important;
        }

        .safe-form {
            width: calc(100%);
            height: 48px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: center;
            color: #F9FBFC;
            background-color: #163F49;
            margin-top: 24px;

            padding: 0;
            border: none;
            border-radius: 4px;
            font-family: "Inter", sans-serif;
            cursor: pointer;
        }

        label {
            display: flex;
            flex-direction: column;
            margin-block: 24px 14px;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
            text-align: left;
            color: #707C7E;
            font-family: "Inter", sans-serif;

            input {
                margin-top: 8px;
                height: 42px;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #0E0E0F;
                padding: 17px 14px 17px 14px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                border-radius: 8px;
                font-family: "Inter", sans-serif;
            }

            input::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #B3BBBC;
            }
        }

        .select-label {
            margin-top: 14px;
            margin-bottom: 24px;
            font-family: "Inter", sans-serif;

            select {
                margin-top: 8px;
                height: 42px;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #0E0E0F;
                padding-left: 14px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                border-radius: 8px;
                font-family: "Inter", sans-serif;
            }

        }
    }

    .preview-form {
        z-index: 10;
        margin-top: 16px;
        background-color: #FFFFFF;
        padding-bottom: 36px;
        max-height: 709px;
        overflow-y: auto;
        overflow-x: hidden;

        h2 {
            font-weight: 600;
            padding-top: 24px;
            font-size: 14px;
        }

        p {
            padding-bottom: 13px;
            font-size: 12px;
        }

        h2,
        p {
            line-height: 16.94px;
            text-align: left;
            color: #0E0E0F;
            padding-left: 24px;
            font-family: "Inter", sans-serif;

        }


        .add-field {
            width: calc(100% - 202px);
            margin: 31px 101px 0 101px;
            height: 48px;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: center;
            color: #F9FBFC;
            background-color: #365961;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            cursor: pointer;
            font-family: "Inter", sans-serif;

            img {
                margin-right: 12px;
            }
        }


    }

    .overflow {
        max-height: 90vh;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .inputs-movile {
        border-radius: 8px 8px 0 0;
        background-color: #F9FBFC;
        width: 100%;
        box-shadow: 0px -2px 10px 0px #163F4940;
        height: auto;
        overflow: auto;
        max-height: 90vh;

        &>img {
            position: absolute;
            background-color: #FFFFFF;
            padding: 10px;
            border-radius: 50%;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            z-index: 10;
        }

        h2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            text-align: left;
            color: #0E0E0F;
            margin: 50px 0 12px 36px;
            font-family: "Inter", sans-serif;
        }

        label {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
            text-align: left;
            margin-left: 36px;
            margin-top: 8px;
            font-family: "Inter", sans-serif;

            input {
                margin-top: 8px;
                padding-left: 14px;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                height: 42px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                width: calc(100% - 36px);
                border-radius: 8px;
                font-family: "Inter", sans-serif;
            }

            input::placeholder {
                color: #B3BBBC;
            }

            .options-select {
                margin-right: 30px;
                max-height: 300px;
                overflow-y: auto;
            }

        }

        .checkbox-add {
            display: flex;
            margin: 18px 36px 0 36px;

            @media (max-width: 375px) {
                flex-direction: column;
                gap: 10px;
            }

            .checkbox {
                display: flex;
                height: 18px;

                p {
                    margin-left: 8px;
                    margin-top: 2px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 14.52px;
                    text-align: left;
                    color: #707C7E;
                    font-family: "Inter", sans-serif;
                }
            }

            .add-input {
                margin-left: auto;
                width: 146px;
                height: 32px;
                border-radius: 4px;
                background-color: #163F49;
                color: #F9FBFC;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: center;
                border: none;
                cursor: pointer;
                font-family: "Inter", sans-serif;

                @media (max-width: 375px) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }

        select {
            margin-top: 31px;
            margin-bottom: 37.63px;
            width: calc(100% - 72px);
            height: 42px;
            border-radius: 7.03px;
            border: 1.1px solid var(--Verde-Dedavara, #163F49);
            padding-left: 16.65px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            font-family: "Inter", sans-serif;

        }
    }



}

.selectBox {
    display: flex !important;
    flex-direction: column;
    gap: 8px !important;
    align-items: flex-start !important;
    min-width: 100% !important;
    margin: 0 !important;
    font-size: 12px !important;
    font-family: "Inter", sans-serif !important;

    p {
        text-align: left !important;
        color: #707C7E !important
    }

    div {
        max-width: 100%;
        width: 100%;
    }



}

@media (max-width: 430px) {
    .template {
        .preview-form {
            .add-field {
                width: calc(100% - 48px);
                margin-left: 24px;
                margin-right: 24px;
            }
        }
    }
}




@media (min-width: 1200px) {
    .selectBox {
        min-width: 249px !important;

        p {
            color: #0E0E0F !important;
            font-size: 16px !important
        }

        div {
            max-width: 20vw;
        }
    }

    .template {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        justify-content: space-between;
        grid-template-areas:
            "title title"
            "save save"
            "preview preview"
            "inputs-desktop inputs-desktop"
        ;
        column-gap: 48px;

        .inputs-movile {
            display: none;
        }

        .title {
            flex-direction: column;
            grid-area: title;

            h3 {
                display: block;
            }

            img {
                display: none;
            }

            h1 {
                margin: 25px 0 0 0;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
            }

        }

        .safe-form-box {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            grid-area: save;
            padding: 24px 32px 24px 24px;
            gap: 15px;

            &>div {
                display: grid;
                grid-template-columns: auto auto;
                width: calc(100% - 24px);
                align-items: center;
                width: 70%;
                min-width: fit-content;
                gap: 32px;



                label {

                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19.36px;
                    text-align: left;
                    color: #0E0E0F;
                    margin: 0;

                    @media (min-width: 1201px) and (max-width:1250px) {
                        width: 200px;
                    }


                    input {
                        width: 100%;
                        height: 51px;
                    }
                }

                .select-label {
                    margin: 0;
                    width: fit-content;
                    min-width: 249px;

                    select {
                        width: 100%;
                        max-width: 250px;
                        height: 48px;
                    }
                }
            }

            .safe-form {
                margin: 0;
                width: 215px;
                height: 48px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                cursor: pointer;
            }
        }

        .preview-form {
            width: 100%;
            grid-area: preview;
            margin: 16px 0 0 0;
            align-self: start;
            height: auto;
            border-radius: 8px;


            h2 {
                font-size: 16px;
                font-weight: 600;
                line-height: 19.36px;
                text-align: left;

            }

            .add-field {
                display: none;
            }
        }

        .inputs-desktop {
            margin: 16px 0 0 0;
            display: block;
            grid-area: inputs-desktop;
        }
    }
}

@media (min-width: 1450px) {
    .template {
        grid-template-areas:
            "title title"
            "save save"
            "inputs-desktop preview";

        .preview-form {
            height: 709px;
        }
    }


}
</style>