<template>
    <div @mousedown="(e) => existModal(e)" class="modal">

        <div class="box" :class="fullWidth && elemntData.dinamic.length > 2 ? 'fullWidth' : 'limitWidth'">

            <div class="loader" v-if="isModalLoader">
                <LoaderComponent  />
            </div>

            <InfoMessage :styleDiv="{ margin: '0' }" v-if="infoMessageData?.isActive"
                :isActive="infoMessageData?.isActive" :color="infoMessageData?.isError ? '#C74D4D' : ''"
                :text="infoMessageData?.text" :icon="infoMessageData?.isError ? 'error' : 'info'" />


            <div v-show="!isModalLoader && !infoMessageData?.isError" class="flex-container">

                <div v-if="!isDinamicForm">
                    <div class="title">
                        <div>
                            <h2>{{ elemntData.name }}</h2>
                            <p class="date">{{ date }}</p>
                        </div>
                        <p v-if="showPersonInCharge" class="author"><span>{{  this.$t('caseFile.detailsCaseFile.task.create-edit.personInCharge')   }}</span> {{
                            elemntData?.createdPerson }}</p>
                    </div>
                    <textarea :value="elemntData.description" disabled></textarea>
                    <!-- Añadir lo de los archivos -->
                    <div class="files" v-if="files?.length > 0">
                        <FileComponent v-for="file in files" :key="file.name" :file="file"
                            @eliminar-archivo="eliminarArcivo" />
                    </div>
                </div>

                <div v-else>
                    <div class="title ">
                        <div class="center">
                            <h2>{{ elemntData.name }}</h2>
                        </div>
                    </div>

                    <div class="grid-element">
                        <div v-for="elemento in elemntData.dinamic" :key="elemento.id">
                            <h3 v-if="elemento.type !== 'Verficación'">{{ elemento.name }} <span
                                    v-if="!elemento.isRequired" class="optionaElement">{{ this.$t('general.optional')  }}</span></h3>
                            <h3 v-else><span class="html-element" v-html="elemento.name"></span> <span
                                    class="optionaElement" v-if="!elemento.isRequired">{{ this.$t('general.optional')  }}</span></h3>

                            <p v-if="elemento.type === 'Input' || elemento.type === 'Selector'">{{ elemento.dataValue ?
                                elemento.dataValue : this.$t('general.inputElements.dinamic.notFill') }}</p>
                            <textarea class="info-textarea" v-if="elemento.type === 'Textarea'"
                                :value="elemento.dataValue ? elemento.dataValue : this.$t('general.inputElements.dinamic.notFill')" disabled></textarea>
                            <p v-if="elemento.type === 'Verficación'">{{ elemento.dataValue ? this.$t('general.inputElements.dinamic.accept') : this.$t('general.inputElements.dinamic.notAccept') }}
                            </p>

                        </div>
                    </div>

                </div>

                <button @click="this.$emit('toggleModalActive')" class="close">{{ this.$t('general.buttons.close')  }}</button>
            </div>


        </div>

    </div>

</template>

<script>
import FileComponent from '../complaint-process/FileComponent.vue';
import InfoMessage from './InfoMessage.vue';
import LoaderComponent from './LoaderComponent.vue';


export default {
    name: "ModalVisualice",
    data() {
        return { date: '' }
    },
    components: { FileComponent, LoaderComponent, InfoMessage },
    props: {
        files: {
            type: Array,
            // required: true
        },
        elemntData: {
            type: Object
        },
        isModalLoader: {
            type: Boolean,
            required: false,
            default: false
        },
        infoMessageData: {
            type: Object
        },
        isDinamicForm: {
            type: Boolean,
            required: false
        },
        fullWidth: {
            type: Boolean,
            required: false
        },
        showPersonInCharge: {
            type: Boolean,
            default: false
        }

    },
    methods: {
        existModal(e) {
            if (e.target.closest('.box')) return
            this.$emit('toggleModalActive')

        },
        formatedDate(fechaISO) {
            // Crear un objeto Date a partir de la cadena de fecha ISO 8601
            const fecha = new Date(fechaISO);

            // Extraer componentes de la fecha en la zona horaria local
            const dia = fecha.getDate().toString().padStart(2, '0'); // Día
            const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
            const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

            // Extraer componentes de la hora en la zona horaria local
            const horas = fecha.getHours().toString().padStart(2, '0'); // Hora en formato 24 horas
            const minutos = fecha.getMinutes().toString().padStart(2, '0'); // Minutos

            // Formatear la fecha y la hora en el formato deseado
            const fechaFormateada = `${dia}/${mes}/${año} - ${horas}:${minutos}`;

            return fechaFormateada;

        }
    },
    watch: {
        elemntData(newValue) {
            this.date = this.formatedDate(newValue.createdAt)

        }
    }
}

</script>

<style scoped lang="scss">
button {
    cursor: pointer;
}

.loader {
    margin-inline: auto;
}

.close {
    color: #163F49;
    font-size: 16px;
    font-family: 'InterMedium';
    border: 1px solid #163F49;
    background-color: transparent;
    border-radius: 4px;
    padding: 7px 14px;
    width: 100%;


}

.fullWidth {
    max-width: 80%;

}

.grid-element {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: auto;
    grid-auto-rows: minmax(90px, auto);
    gap: 10px;

    div {
        border: 1px solid #B3BBBC;
        border-radius: 4px;
        padding: 10px;

        p,
        textarea {
            color: #0E0E0F !important
        }

    }

    h3 {
        color: #707C7E !important;
        font-size: 16px !important;
        font-family: 'InterRegular', sans-serif !important;
        font-weight: 400 !important;
    }


    @media (max-width:500px) {
        display: flex;
        flex-direction: column;
    }

    h3 {
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;

        span {
            font-size: 16px;
        }

        @media (max-width:300px) {
            font-size: 14px;

            span {
                font-size: 14px;
            }
        }
    }

    .optionaElement {
        font-family: 'InterRegular', sans-serif;
        font-size: 12px;
        color: #A2ABAD;

        @media (max-width:300px) {
            font-size: 10px;
        }
    }


}

p,
textarea {
    font-size: 14px;
}

.center {
    h2 {
        width: 100%;
        text-align: center;
        color: #163F49 !important;
        margin-bottom: 30px !important;
        font-family: 'InterRegular', sans-serif;
    }
}

.limitWidth {
    max-width: 497px;
}


.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding-block: 20px;
    flex-direction: column;




    .box {
        overflow: auto;
        width: 100%;

        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.flex-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    display: flex;
    flex-direction: column;
    gap: 14px;

    h2,
    .date {
        color: #0E0E0F;
        font-family: 'InterMedium';
        margin: 0;
    }

    h2 {
        font-size: 24px;

    }

    .date {
        font-size: 16px;
        margin-left: auto;

    }

    .author {
        text-align: right;
        font-family: 'InterRegular';
        color: #0E0E0F;

        span {
            font-family: 'InterMedium';
        }
    }

    &>div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 14px;
    }

}

textarea {
    border: none;
    min-height: 206px;
    line-height: 18.2px;
    font-family: 'InterRegular';
    font-size: 14px;
    resize: none;
    color: #0E0E0F;
    padding-right: 5px;
    background-color: transparent;
    max-width: 100%;
    margin-top: 10px;

}

.info-textarea {
    min-height: auto;
    border: 1px solid #0E0E0F;
    border-radius: 4px;
    padding: 5px 8px;
    width: 100%;
}

.files {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 10px 0 16px;
    border-top: none;
    border-radius: 0 0 7.03px 7.03px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    overflow-y: scroll;
    max-height: 75px;

}

@media (max-width: 475px) {
    .title {
        h2 {
            font-size: 20px;
        }
    }
}
</style>