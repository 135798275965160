<template>
    <div class="box">
        <div>
            <div class="input">
                <h2>{{ this.$t('complainForm.create-edit.addElements.input.headerTitle') }}</h2>
                <div class="blank">
                    <InfoMessage :text="this.$t('complainForm.create-edit.addElements.error')" :isActive="input.error"
                        icon="error" color="#C74D4D" />
                </div>
                <label>
                    {{ this.$t('complainForm.create-edit.addElements.input.title') }}
                    <input :title="input.value" :style="input.error && { borderColor: '#C74D4D' }" type="text"
                        v-model.trim="input.value" :placeholder="this.$t('complainForm.create-edit.addElements.generalPlaceholder')">
                </label>
                <div class="checkbox-add">
                    <div class="checkbox">
                        <Checkbox v-model="checkboxOptions.input" labelIdenty="create-input" />
                        <label for="create-input">{{ this.$t('complainForm.create-edit.addElements.required') }}</label>
                    </div>
                    <button class="add-button"
                        @click="emitInput(input.value, this.$t('complainForm.create-edit.previewElement.type.input'), 'input', checkboxOptions.input)">{{ this.$t('complainForm.create-edit.addElements.button') }}</button>
                </div>


            </div>
            <div class="selector">
                <h2>{{ this.$t('complainForm.create-edit.addElements.select.headerTitle') }}</h2>
                <div class="blank">
                    <InfoMessage :text="select.errorMessage" :isActive="select.error" icon="error" color="#C74D4D" />
                </div>
                <label>
                    {{ this.$t('complainForm.create-edit.addElements.select.title') }}
                    <input :title="select.value" type="text" v-model.trim="select.value" class="input-name-select"
                        :placeholder="this.$t('complainForm.create-edit.addElements.generalPlaceholder')">
                </label>
                <label>
                    {{ this.$t('complainForm.create-edit.addElements.select.options.title') }}
                    <div class="options-select">
                        <OptionsSelect ref="optionsSelect" @optionsChanged="updateOptions" />
                    </div>
                </label>
                <div class="checkbox-add">
                    <div class="checkbox">
                        <Checkbox v-model="checkboxOptions.select" labelIdenty="create-selector" />
                        <label for="create-selector">{{ this.$t('complainForm.create-edit.addElements.required') }}</label>
                    </div>
                    <button @click="emitSelect(checkboxOptions.select)" class="add-button">{{ this.$t('complainForm.create-edit.addElements.button') }}</button>
                </div>
            </div>

            <!--TO DO: esto refactorizazlo todos los que son de input-->
            <div class="input">
                <h2>{{ this.$t('complainForm.create-edit.addElements.textarea.headerTitle') }}</h2>
                <div class="blank">
                    <InfoMessage :text="this.$t('complainForm.create-edit.addElements.error')" :isActive="textarea.error"
                        icon="error" color="#C74D4D" />
                </div>
                <label>
                    {{ this.$t('complainForm.create-edit.addElements.textarea.title') }}
                    <input :title="textarea.value" :style="textarea.error && { borderColor: '#C74D4D' }" type="text"
                        v-model.trim="textarea.value" :placeholder="this.$t('complainForm.create-edit.addElements.generalPlaceholder')">
                </label>
                <div class="checkbox-add">
                    <div class="checkbox">
                        <Checkbox v-model="checkboxOptions.textarea" labelIdenty="create-textarea" />
                        <label for="create-textarea">{{ this.$t('complainForm.create-edit.addElements.required') }}</label>
                    </div>
                    <button class="add-button"
                        @click="emitInput(this.textarea.value, this.$t('complainForm.create-edit.previewElement.type.textarea'), 'textarea', checkboxOptions.textarea)">{{ this.$t('complainForm.create-edit.addElements.button') }}</button>
                </div>
            </div>

            <!--De momento lo tenemos ocultado porque es posible que se use en un futuro
            <div class="input-file">
                <h2>Nuevo Input File <span>(Subida de archivos)</span></h2>
                <div class="blank">
                    <InfoMessage text="Se debe rellenar el campo para poder ser añadido" :isActive="file.error"
                        icon="error" color="#C74D4D" />
                </div>
                <label>
                    Título del Input File
                        <input :title="file.value" :style="file.error && { borderColor: '#C74D4D' }" type="text" v-model.trim="file.value"
                            placeholder="Describa su Input File">
                </label>
                <div class="checkbox-add">
                    <div class="checkbox">
                        <Checkbox v-model="checkboxOptions.file" labelIdenty="create-file"  />
                        <label for="create-file">Es obligatorio</label>
                    </div>
                    <button @click="emitInput(this.file.value, 'Input File', 'file', checkboxOptions.file)" class="add-button">Añadir</button>
                </div>

            </div>

             -->

            <!-- casilla de verificación -->
            <div class="input">
                <h2>{{ this.$t('complainForm.create-edit.addElements.verification.headerTitle') }}</h2>
                <div class="blank ">
                    <InfoMessage :text="verification.errorText" :isActive="verification.error" icon="error"
                        color="#C74D4D" />
                </div>
                <div class="verification-label">
                    <div class="verification-description">
                        <label @click="this.$refs.textInput.focus()">{{ this.$t('complainForm.create-edit.addElements.verification.title') }}</label>
                        <div class="button-link-container">
                            <div class="flex">
                                <button @click="eliminateLink" :title="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"><img
                                        src="@/assets/icons/linkOff.svg" :alt="this.$t('complainForm.create-edit.addElements.verification.deleteLinks')"></button>
                                <button :title="this.$t('complainForm.create-edit.addElements.verification.addLink')" @click="addLink"><img src="@/assets/icons/link.svg"
                                        :alt="this.$t('complainForm.create-edit.addElements.verification.addLink')"></button>
                            </div>

                            <input type="text" v-model="linkHref" :placeholder="this.$t('complainForm.create-edit.addElements.verification.placeholderLink')">
                        </div>
                    </div>



                    <div @click="this.$refs.textInput.focus()"
                        :style="isFocusable ? { border: '2px solid #0E0E0F' } : null" class="verification"
                        :class="verification.error && 'borderRed'">
                        <div @keydown="prevenirEnter" :class="!verification.value && 'placeholderClass'"
                            @focus="isFocusable = true" @blur="isFocusable = false" @paste="handlePaste"
                            contenteditable="true" @input="updateValue" @mousedown="isVerificationMouseDown = true"
                            ref="textInput">
                        </div>
                    </div>

                </div>
                <div class="checkbox-add">
                    <div class="checkbox">
                        <Checkbox v-model="checkboxOptions.verification" labelIdenty="create-verification" />
                        <label for="create-verification">{{ this.$t('complainForm.create-edit.addElements.required') }}</label>
                    </div>
                    <button class="add-button add-verification"
                        @click="emitInput(this.verification.value, 'Verficación', 'verification', checkboxOptions.verification)">{{ this.$t('complainForm.create-edit.addElements.button') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import Checkbox from './Checkbox.vue';
import OptionsSelect from './OptionsSelect.vue';
import InfoMessage from '../general/InfoMessage.vue';
import verificationMixing from './verificationMixing';

export default {
    name: 'NewInputs',
    data() {
        return {
            isFocusable: false,
            isVerificationMouseDown: false,
            linkHref: '',
            linksReference: [],
            input: {
                value: '',
                error: false
            },
            select: {
                value: '',
                error: false,
                errorMessage: ''
            },
            file: {
                value: '',
                error: false
            },
            textarea: {
                value: '',
                error: false
            },
            verification: {
                value: '',
                error: false,
                linkButton: false,
                errorText: ''
            },
            optionsError: false,
            options: [],
            checkboxOptions: {
                input: false,
                select: false,
                file: false,
                textarea: false,
                verification: false
            },

        };
    },
    mixins: [verificationMixing],
    components: {
        Checkbox,
        OptionsSelect,
        InfoMessage
    },

    methods: {
        emitInput(value, type, vModelName, isRequired) {
            this[vModelName].error = false
            if (value.trim() === '') {
                if (vModelName === 'verification') {
                    this.verification.errorText = this.$t('complainForm.create-edit.addElements.error')
                }
                this[vModelName].error = true
                setTimeout(() => {
                    this[vModelName].error = false
                }, 2000)
                return
            }

            if (vModelName === 'verification') {
                this.verification.linkButton = false

                let htmlContent = this.$refs.textInput.innerHTML;

                // Reemplazar todos los &nbsp; con espacios regulares y luego aplicar el trim.
                let trimmedContent =  htmlContent.replace(/&nbsp;/g, ' ').replace(/^\s+|\s+$/g, '');
                let safeContent = trimmedContent.replace(/(\s)(<[^>]+>)/g, '&nbsp;$2');
          
                const sanetizeValue = this.sanitizeHTMLOnlyAllowA(safeContent)

                this.$emit('inputAdded', { value: sanetizeValue, type: type, isRequired, vModelName });
                this[vModelName].value = ''
                const editableDiv = this.$refs.textInput
                editableDiv.textContent = ''

            } else {
                this.$emit('inputAdded', { value: value, type: type, isRequired, vModelName });
                this[vModelName].value = ''
            }

        },
        setCheckBoxToFalse(nameCheckbox) {
            this.checkboxOptions[nameCheckbox] = false
        },
        emitSelect(isRequired) {

            this.optionsError = false
            this.select.error = false
            //comprobamos que el titulo no está en blanco
            if (this.select.value === '') {
                this.select.error = true
                this.select.errorMessage = this.$t('complainForm.create-edit.addElements.titleError')
                setTimeout(() => {
                    if (this.optionsError) return
                    /*Esto se hace para evitar que si alguien clica y aparece el titulo vacio y antes de 
                    que termine el tiempo clica sin aadir opciones que no se quite el mensaje de
                    opciones rapidamente
                    */
                    this.select.error = false
                }, 2000)
                return
            }
            const opt = this.options.map(option => option.value).filter(option => option !== '')

            //comprobamos que al menos se ha elegido una opción
            this.optionsError = true
            if (opt.length === 0) {
                this.select.error = true
                this.select.errorMessage = this.$t('complainForm.create-edit.addElements.select.options.error')
                setTimeout(() => {
                    this.select.error = false
                }, 2000)
                return
            }

            //enviamos
            this.$emit('selectAdded', opt, this.select.value, isRequired);
            this.select.value = ''
            this.$refs.optionsSelect.cleanOption()


        },
        updateOptions(options) {
            this.options = options;
        },
        textDrop() {
            this.$emit('textCloned', this.input);
        },
        fileDrop() {
            this.$emit('fileCloned', this.file);
        },
        selectDrop() {
            const opt = this.options.map(option => option.value).join(', ');
            this.$emit('selectCloned', opt, this.select);
        }

    },

}
</script>

<style scoped lang="scss">
.blank {
    margin-inline: 24px 32px;
    margin-top: 10px;
}


.placeholderClass::before {
    content: 'Texto';
    pointer-events: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left;
    color: #B3BBBC;
}

.flex {
    display: flex;
    justify-content: space-around;
}


.verification-label {
    margin-inline: 24px 32px;
    display: grid;
    grid-template-columns: 1fr;
    width: calc(100% - 56px);

}

.verification-description {
    position: relative;

    label {
        margin-left: 0 !important;
    }
}

.button-link-container {
    position: absolute;
    top: calc(-65%);
    right: 0;
    background: white;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    width: 150px;
    border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
    border-radius: 3px;
    padding: 1px;

    button {
        background: transparent;
        border: none;
        cursor: pointer;

        img {
            height: 20px;
            width: 20px;
        }
    }

    input {
        border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
        color: #0E0E0F;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        padding: 2px;
        font-size: 10px;
        height: 30px;

    }

    input::placeholder {
        text-align: left;
        color: #B3BBBC;
    }
}

.box {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding-top: 32px;
    padding-bottom: 32px;
    border-radius: 8px;

    &>div {
        max-height: 645px;
        overflow-y: auto;
        margin-right: 24px;
    }

    .verification {
        cursor: text;

        margin-top: 8px;


        /* Ajusta el padding para que se parezca más a un input */



        display: grid;
        border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
        border-radius: 8px;

        /* Esto incluye el padding y el borde en el ancho total */

        &>div {
            margin-inline: auto;
            font-size: 14px;
            text-align: left;
            font-weight: 400;
            color: #0E0E0F;
            font-family: "Inter", sans-serif;

            padding: 17px 14px;
            width: 95%;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            vertical-align: middle;
            box-sizing: border-box;

            &:focus {
                outline: none;
            }
        }



    }

    .borderRed {
        border: 1.25px solid var(--Gris--1-Dedavara, #C74D4D) !important;
    }

    .input,
    .selector,
    .input-file {
        h2 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.36px;
            text-align: left;
            color: #0E0E0F;
            margin-left: 24px;
            font-family: "Inter", sans-serif;

            span {
                color: #55737A;
                font-weight: 400;
                font-family: "Inter", sans-serif;
            }
        }

        label {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            color: #707C7E;
            display: flex;
            flex-direction: column;
            margin-left: 24px;
            margin-top: 18px;
            font-family: "Inter", sans-serif;
            margin-right: 32px;

            input {
                width: 100%;
                margin-top: 8px;
                padding: 17px 0 18px 14px;
                color: #0E0E0F;
                font-size: 14px;
                font-weight: 400;
                border-radius: 8px;
                border: 1.25px solid var(--Gris--1-Dedavara, #B3BBBC);
                font-family: "Inter", sans-serif;


            }

            input::placeholder {
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
                color: #B3BBBC;
            }

            .options-select {
                margin-left: 8px;
                max-height: 300px;
                overflow-y: auto;
            }
        }

        .checkbox-add {
            margin-top: 19px;
            display: flex;
            margin-left: 24px;
            margin-right: 32px;
            margin-bottom: 24px;

            .checkbox {
                display: flex;
                height: 18px;

                label {
                    cursor: pointer;
                    margin-top: 2px;
                    display: block;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    text-align: left;
                    color: #707C7E;
                    margin-left: 8px;
                    text-wrap: nowrap;
                    font-family: "Inter", sans-serif;
                }
            }

            .add-button {
                margin-left: auto;
                width: 146px;
                height: 32px;
                border-radius: 4px;
                background-color: #163F49;
                color: #F9FBFC;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: center;
                cursor: pointer;
                font-family: "Inter", sans-serif;
                border: none;
            }
        }
    }

    .verification {
        width: 100%;
        margin-left: 0;
    }

    .selector {
        position: relative;


        label {

            input {
                width: 100%;
                margin-left: 48px;
                font-family: "Inter", sans-serif;

            }

            .input-name-select {
                width: 100%;
                margin-left: 0;

            }


        }
    }


}
</style>