<template>
    <div @click="(e) => existModal(e)" class="modal" ref="modal">
        <div v-if="!isErrorForElementUseInOtherPart && !isDeleteError" class="box">
            <h2>{{ deleteModalText.title }}</h2>
            <p v-if="option === 'multiple'">{{ `${this.$t('general.deleteModal.beforeTheNumber')}
                ${this.selectedElements.length}` }} {{
                    deleteModalText.namePlural }}.</p>
            <p v-if="option === 'simple'">{{ deleteModalText.textSingular }}</p>
            <div class="buttons">
                <button class="cancel" @click="toggleModal">{{ this.$t('general.buttons.cancel') }}</button>
                <button :disabled="isSpinnerBtn" v-if="option === 'multiple'" class="delete"
                    :class="isSpinnerBtn ? 'disable' : null" @click="deleteCategories">
                    <VueSpinnerToBtn ref="spinnerBtnAction">
                        {{ this.$t('general.buttons.delete') }}
                    </VueSpinnerToBtn>
                </button>
                <button :disabled="isSpinnerBtn" v-if="option === 'simple'" class="delete"
                    :class="isSpinnerBtn ? 'disable' : null" @click="deleteSingularCategory(lastSingularId)">
                    <VueSpinnerToBtn ref="spinnerBtnAction2">
                        {{ this.$t('general.buttons.delete') }}
                    </VueSpinnerToBtn>
                </button>
            </div>
        </div>

        <div v-if="isErrorForElementUseInOtherPart" class="box">
            <h2>Error</h2>
            <InfoMessage :isActive="true" color="#C74D4D"
                :text="option === 'simple' ? deleteModalText.errorSingular : deleteModalText.errorPlural"
                icon="error" />
            <div class="buttons">
                <button class="cancel" @click="toggleModal">{{ this.$t('general.buttons.cancel') }}</button>
            </div>
        </div>

        <div v-if="isDeleteError" class="box">
            <h2>Error</h2>
            <InfoMessage :isActive="true" color="#C74D4D" :text="this.$t('general.infoMessage.generalError')"
                icon="error" />
            <div class="buttons">
                <button class="cancel" @click="toggleModal">{{ this.$t('general.buttons.cancel') }}</button>
            </div>
        </div>


    </div>

</template>


<script>
import axios from 'axios';
import InfoMessage from '../general/InfoMessage.vue';
import VueSpinnerToBtn from '../general/VueSpinnerToBtn.vue';

export default {
    name: 'DeleteModalTable',
    data() {
        return { isErrorForElementUseInOtherPart: false, isDeleteError: false, isSpinnerBtn: false }
    },
    components: {
        InfoMessage,
        VueSpinnerToBtn
    },
    props: {
        selectedElements: {
            type: Array
        },
        option: {
            type: String,
            required: true
        },
        lastSingularId: {
            type: String
        },
        path: {
            type: String,
            required: true
        },
        deleteModalText: {
            type: Object,
            required: true
        },
        cleanSelectActive: {
            type: Boolean,
            required: false,
            default: true
        }

    },
    methods: {
        activeSpinner(element) {
            this.$refs[element].activeSpinner()
            this.isSpinnerBtn = true
        },
        desactiveSpinner(element) {
            this.$refs[element].activeSpinner()
            this.isSpinnerBtn = false
        },
        toggleModal() {
            this.$emit('toggleModal');
        },
        existModal(e) {
            if (e.target.closest('.box')) return
            this.toggleModal()

        },
        async deleteCategories() {
            this.activeSpinner('spinnerBtnAction')
            let errorArray = [];
            let isError = false;

            for (let categoryId of this.selectedElements) {
                try {
                    await axios.delete(`${this.path}/${categoryId}`);
                } catch (error) {
                    if (error.response.status === 500) {
                        errorArray.push(categoryId);
                    }
                    isError = true;
                }
            }

            this.$emit('trigger-fetch');
            this.$emit('eliminateSelectedCategories');

            if (isError) {
                if (errorArray.length > 0) {
                    this.isErrorForElementUseInOtherPart = true;
                } else {
                    this.isDeleteError = true;
                }
            } else {
                this.toggleModal();
            }

            this.$emit('checkboxToFalse');
            this.desactiveSpinner('spinnerBtnAction')
            if (this.path === '/forms') {
                //actualizamos el boton del enlace de la denuncia para mostrar o no mostrar error
                this.$store.commit('setChangeFormToggle');
            }

        },
        deleteSingularCategory(id) {
            this.activeSpinner('spinnerBtnAction2')

            const identificador = id
            axios.delete(`${this.path}/${id}`)
                .then(() => this.$emit('trigger-fetch')).then(() => {
                    if (!this.cleanSelectActive) return
                    this.$emit('cleanSelectedCategories', identificador)
                }).then(() => {
                    this.desactiveSpinner('spinnerBtnAction2')
                    this.toggleModal()
                    if (this.path === '/forms') {
                        //actualizamos el boton del enlace de la denuncia para mostrar o no mostrar error
                        this.$store.commit('setChangeFormToggle');
                    } else if(this.path === '/companies') {
                        //para actualizar el selector de empresas
                        this.$store.commit('setCompanyChangeToggle')
                    }
                })
                .catch(error => {
                    console.error(error);
                    if (error.response.status === 500) {
                        this.isErrorForElementUseInOtherPart = true
                    } else {
                        this.isDeleteError = true
                    }
                    this.desactiveSpinner('spinnerBtnAction2')

                })
                

        }
    }

}

</script>

<style scoped>
button {
    cursor: pointer;
}

h2 {
    margin-top: 0;
}

.disable {
    cursor: wait !important;
}

.modal {

    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    flex-direction: column;
    padding-block: 20px;


    .box {
        overflow: auto;
        width: 90%;
        max-width: fit-content;
        background-color: #FFFFFF;
        padding: 24px;
        border-radius: 8px;
        text-align: left;

        .buttons {
            margin-top: 24px;
            display: flex;
            width: 100%;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;



            button {
                width: 121px;
                height: 32px;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: center;
                border: none;
                background: none;
                font-family: "Inter", sans-serif;
            }

            .delete {
                background-color: #C74D4D;
                color: #F9FBFC;
            }

            .cancel {
                border: 1px solid var(--Verde-Dedavara, #163F49);
                color: #163F49;
            }
        }
    }
}

.template {

    margin-top: 23.5px;

    .title {

        .delete-categories {
            width: calc(100% - 48px);
            margin: 24px 24px 0 24px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: center;
            background-color: #C74D4D;
            color: #F9FBFC;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            border: none;
            font-family: "Inter", sans-serif;
        }

        h3 {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            color: #A2ABAD;
            display: none;
            font-family: "Inter", sans-serif;
        }

        h1 {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.2px;
            text-align: left;
            color: #163F49;
            margin-left: 24px;
            font-family: "Inter", sans-serif;
        }

        .search {
            width: calc(100% - 48px);
            margin: 26px 24px 0 24px;
        }

        .new-category {
            width: calc(100% - 48px);
            margin: 24px 24px 0 24px;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: center;
            background-color: #163F49;
            color: #F9FBFC;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-family: "Inter", sans-serif;


            .new-category-button {
                width: 100%;

            }
        }
    }

    .table {
        margin-top: 18px;
        padding: 12px 12px;
        background: #FFFFFF;
    }


}

@media (max-width: 350px) {

    h2 {
        font-size: 1.2rem;
    }

}

@media (min-width: 450px) {
    .template {
        .title {
            .new-delete {
                display: flex;
                flex-direction: row-reverse;
            }
        }
    }



}

@media (min-width: 1200px) {
    .template {
        margin: 38px 44px 0 78px;

        .title {
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto auto;
            margin-bottom: 48px;

            h1,
            h3,
            .search,
            .new-category {
                margin: 0;
            }

            h3 {
                display: block;
                grid-column: span 2;
                height: auto;
            }

            h1 {
                margin-top: 25px;
                font-size: 30px;
                font-weight: 600;
                line-height: 36.31px;
                text-align: left;
            }

            .new-category {
                width: 238px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                margin-left: auto;
                margin-top: auto;
            }

            .delete-categories {
                width: 238px;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                margin-left: auto;
                margin-top: auto;
                margin-right: 0;
            }

            .search {
                margin-top: 24px;
                width: 100%;
                grid-column: span 2;
                grid-row-start: 3;
            }

            .new-delete {
                button {
                    margin: 0;
                }

                .delete-categories {
                    margin-top: auto;
                }

                .new-category {
                    margin-left: 24px;
                }

            }
        }

        .table {
            margin-top: 0;
        }

    }

}
</style>