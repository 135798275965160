<template>
    <div>
        <generalTitle :divStyle="{ width: 'fit-content' }" :breadcrumbs="breadcrumbs" :title="title" :root="true"
            @goBack="this.goBack()" />
    </div>

    
   


</template>


<script>
import generalTitle from '../general/generalTitle.vue';

export default {
    name: 'TraslationsComponent',
    components: { generalTitle},
    data() {
        return {
            breadcrumbs: [
                { label: 'Traducciones', to: '/translations' },
            ],
            title: "Traducciones",
        }
    },
    
}


</script>

<style scoped lang="scss">

</style>
