<template>
  <CaseFileLayaout :titleName="titleName" :createbutton="createbutton" :pathData="pathData" :breadcrumbs="breadcrumbs"
    :dropdownData="dropdownData" :deleteModalInfo="deleteModalInfo" :isPermited="isPermited" :noElementsMessage="this.$t('caseFile.noCreateCaseFile')" />

</template>



<script>
import CaseFileLayaout from '../general/CaseFileLayaout.vue';


export default {
  name: "CaseFilesComponent",
  components: {
    CaseFileLayaout
  },
  data() {
    return {
      isPermited: true,
      titleName: this.$t('caseFile.showCaseFile.title.caseFile'),
      createbutton: {
        to: '/expedientes/create-proceeding',
        text: this.$t('caseFile.showCaseFile.buttons.newCasefile'),
      },
      isSubCase: false,
      pathData: {
        getData: '/case_files',
        pathKey: 'files'
      },
      breadcrumbs: [{ label: this.$t('caseFile.showCaseFile.breadcrumb.first'), to: '/expedientes' }],
      deleteModalInfo: {
        deleteModalText: {
          title: this.$t('caseFile.showCaseFile.deleteModal.titleCaseFile'),
          textSingular: this.$t('caseFile.showCaseFile.deleteModal.textSingularCasefile'),
          errorSingular: this.$t('caseFile.showCaseFile.deleteModal.errorSingular')
        },
        path: "/case_files"
      }
    }
  },
  created() {
    const rolesToCheck = ["ROLE_COMPANY", "ROLE_COMPANY_ADMIN"];

    if (!rolesToCheck.some(role => this.$store.getters.getRoles.includes(role))) {
      this.isPermited = false
    }
  },
  methods: {
    dropdownData(caseID) {
      if (this.$store.getters.getRoles.includes('ROLE_INSTRUCTOR')) {
        return [
          { text: this.$t('general.dropdownOptions.visualice'), href: '/expedientes/' + caseID },
          { text: this.$t('general.dropdownOptions.edit'), href: '/expedientes/editar-expediente/' + caseID },
          { text: this.$t('general.dropdownOptions.subcaseFile'), href: '/expedientes/sub-expediente/' + caseID }
        ]
      } else {
        return [
          { text: this.$t('general.dropdownOptions.visualice'), href: '/expedientes/' + caseID },
          { text: this.$t('general.dropdownOptions.edit'), href: '/expedientes/editar-expediente/' + caseID },
          { text: this.$t('general.dropdownOptions.delete'), data: { action: 'toggleModalSimple', id: caseID }, estilos: { color: '#C74D4D' } },
          { text: this.$t('general.dropdownOptions.subcaseFile'), href: '/expedientes/sub-expediente/' + caseID }
        ]
      }


    }
  },
}


</script>
