<template>

    <div ref="caseFileCreateEditLayaout" class="template">
        <div class="title">
            <generalTitle :title="header.title" :breadcrumbs="header.breadcrumbs" :root="false" @goBack="goBack()" />
        </div>
        <div class="center" v-if="isLoading">
            <Loader />
        </div>
        <div class="get-data-error" v-else-if="isGetDataError">
            <InfoMessage :isActive="true" color="#C74D4D" :text="this.$t('general.infoMessage.generalError')"
                icon="error" />
        </div>

        <div class="modal" v-else-if="!isLoading && isModalActive">
            <div>
                <h1>{{ this.$t('caseFile.createEditCaseFile.infoMessageRequired') }}</h1>
                <ul>
                    <li v-for="(elemnt, index) in elemntNeedToContinue" :key="elemnt + index">- {{ elemnt }}</li>
                </ul>
            </div>
        </div>

        <div v-else-if="!isLoading && !isModalActive">

            <div :class="{ 'info-message': true, 'active': infoMessage.isActive, 'notActive': !infoMessage.isActive }">
                <InfoMessage :isActive="infoMessage.isActive" :color="infoMessage.icon === 'info' ? null : '#C74D4D'"
                    :text="infoMessage.text" :icon="infoMessage.icon" />
            </div>
            <form @submit.prevent="isEdit ? editCaseFile() : addCaseFile()">

                <div class="grid">

                    <label for="asunto-expediente" class="colum1">
                        <p>{{ this.$t('general.inputElements.fileSubject') }}</p>
                        <SelectComponent v-model:selectedValue.trim="fileData.subjectFile.value" :isInput="true"
                            labelIndentify="asunto-expediente" @cleanFileDataError="cleanFileDataError"
                            :error="fileData.subjectFile.error" fileDataName="subjectFile"
                            :isDisable="checkIsNotInternal() && !this.isInformant ? true : false" />
                    </label>

                    <label v-if="!this.isInformant" for="departamento" class="colum2">
                        <p>{{ this.$t('general.inputElements.department.title') }}</p>
                        <SelectSearchBox v-model:selectedValue="fileData.department.value"
                            :styleErrorMessage="{ marginTop: '6px' }" :optionArray="fetchData.department"
                            labelIndentify="departamento"
                            :placeholderText="this.$t('general.inputElements.department.placeholder')"
                            :error="fileData.department.error" fileDataName="department"
                            @cleanFileDataError="cleanFileDataError" />
                    </label>

                    <label for="categorias" :class="isInformant ? 'colum2' : 'colum1'">
                        <p>{{ this.$t('general.inputElements.category.title') }}</p>

                        <SelectSearchBox v-model:selectedValue="fileData.categories.value"
                            :isDisabled="isInternalCase === false" :styleErrorMessage="{ marginTop: '6px' }"
                            :optionArray="fetchData.categories" labelIndentify="categories"
                            :placeholderText="this.$t('general.inputElements.category.placeholder')"
                            :error="fileData.categories.error" fileDataName="categories"
                            @cleanFileDataError="cleanFileDataError" />

                    </label>

                    <label for="intructor" class="colum2" v-if="!this.isInstructor && !this.isInformant">
                        <p>{{ this.$t('general.inputElements.instructor.title-two') }}</p>
                        <SelectSearchBox v-model:selectedValue="fileData.instructor.value"
                            :styleErrorMessage="{ marginTop: '6px' }" :optionArray="fetchData.instructor"
                            labelIndentify="instructores"
                            :placeholderText="(this.fileData.instructor.value === '' || this.fileData.instructor.value.length === 0) && this.isEdit ? this.$t('general.inputElements.noAssing') : this.$t('general.inputElements.instructor.placeholder')"
                            :error="fileData.instructor.error" fileDataName="instructor"
                            @cleanFileDataError="cleanFileDataError" :isMultiple="true" />
                    </label>


                    <div class="estado-prioridad" :class="this.isInstructor ? 'colum2' : 'colum1'">
                        <label for="estado">
                            <p>{{ this.$t('general.inputElements.state.title') }}</p>
                            <SelectSearchBox v-model:selectedValue="fileData.state.value" :isDisabled="this.isInformant"
                                :styleErrorMessage="{ marginTop: '6px' }" :optionArray="fetchData.states"
                                labelIndentify="estado"
                                :placeholderText="this.$t('general.inputElements.state.placeholder')"
                                :error="fileData.state.error" fileDataName="state"
                                @cleanFileDataError="cleanFileDataError" />

                        </label>
                    </div>
                    <div v-if="!this.isInformant" :class="this.isInstructor ? 'colum1' : 'colum2'">
                        <label for="prioridad">
                            <p>{{ this.$t('general.inputElements.priority.title') }}</p>
                            <SelectSearchBox v-model:selectedValue="fileData.priority.value"
                                :optionArray="fetchData.priority" labelIndentify="prioridad"
                                :styleErrorMessage="{ marginTop: '6px' }"
                                :placeholderText="this.$t('general.inputElements.priority.placeholder')"
                                :error="fileData.priority.error" fileDataName="priority"
                                @cleanFileDataError="cleanFileDataError" :selectDivContainer="{ width: '130px' }" />
                        </label>

                    </div>

                    <!--DINAMICOS-->
                    <div class="dinamic"
                        :class="isInstructor || isInformant ? (index % 2 === 0 ? 'colum2' : 'colum1') : (index % 2 === 0 ? 'colum1' : 'colum2')"
                        :key="element.id" v-for="(element, index) in dinamicInputArray">
                        <DinamicInput v-if="this.dinamicInputArray.length > 0" :ref="'DinamicInput' + element.id"
                            :type="element.type" :label="element.name" :dataValue="element.dataValue" :showOption="true"
                            :isRequired="element.isRequired" :options="element.options" :identy="element.id"
                            :textareDivStyle="{ display: 'grid', height: '100%', gridTemplateRows: 'auto 1fr' }"
                            :textareStyle="{ minHeight: 'auto' }"
                            :isDisabled="checkIsNotInternal() && !this.isInformant ? true : false" />
                    </div>
                    <!--FIN DINAMICOS-->


                    <label for="denuncia-textArea" class="colum3">
                        <p>{{ this.$t('general.inputElements.complaint.title') }}</p>
                        <div class="textarea-container"
                            :class="{ 'error': fileData.report.error && fileData.report.value === '' }">
                            <textarea v-model.trim="fileData.report.value" name="denuncia-textArea"
                                id="denuncia-textArea"
                                :disabled="checkIsNotInternal() && !this.isInformant ? true : false"
                                :placeholder="this.$t('general.inputElements.complaint.placeholder')"></textarea>
                            <div class="files" v-if="files.length > 0">
                                <FileComponent v-for="file in files" :key="file.name" :file="file"
                                    @eliminar-archivo="eliminarArcivo"
                                    :isDisabled="checkIsNotInternal() && !this.isInformant ? true : false" />
                            </div>
                        </div>
                        <p class="error-message" v-if="fileData.report.error && fileData.report.value === ''">{{
                            this.$t('general.infoMessage.fillElement') }}</p>
                    </label>

                    <div class="colum4">
                        <div class="button-container">
                            <div class="file">
                                <input class="fileTextHiden  pointer" ref="fileInput" type="file" id="fileInput"
                                    multiple @change="handleFileUpload"
                                    :disabled="checkIsNotInternal() && !this.isInformant ? true : false">
                                <label class="pointer" for="fileInput"><img src="@/assets/icons/clip.svg"> {{
                                    this.$t('general.textareaButtons.addFile') }} </label>
                            </div>
                            <div class="file">
                                <input class="fileTextHiden  pointer" ref="fileInput" type="file" accept="audio/*"
                                    id="fileInputAudio" multiple @change="handleFileUpload"
                                    :disabled="checkIsNotInternal() && !this.isInformant ? true : false">
                                <label class="pointer" for="fileInputAudio"><img src="@/assets/icons/microphone.svg"> {{
                                    this.$t('general.textareaButtons.voiceNote') }} </label>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="sumbit-button-container">
                    <button :disabled="disableBtn" type="submit" :class="disableBtn ? 'disableBtn' : ''">
                        <VueSpinnerToBtn ref="spinnerActionBtn">
                            {{ submitButtonTitle }}
                        </VueSpinnerToBtn>
                    </button>
                    <div v-show="isBlank" ref="isBlankMessage">
                        <InfoMessage :isActive="true" color="#C74D4D"
                            :text="this.$t('general.infoMessage.needFillRequiredElements')" icon="error" />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>


<script>

import generalTitle from '../general/generalTitle.vue';
import SelectComponent from '@/components/general/SelectComponent.vue'
import FileComponent from '@/components/complaint-process/FileComponent.vue';
import InfoMessage from '../general/InfoMessage.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Loader from './LoaderComponent.vue';
import SelectSearchBox from '../forms/SelectSearchBox.vue';
import DinamicInput from '../complaint-process/DinamicInput.vue';
import VueSpinnerToBtn from './VueSpinnerToBtn.vue';
import { translationStateDefaultMixing, translationCategoryDefaultMixing } from "@/components/translationDefaultMixing.js"


export default {
    name: 'CaseFileCreateEditLayout',
    components: {
        generalTitle,
        SelectComponent,
        FileComponent,
        InfoMessage,
        Loader,
        SelectSearchBox,
        DinamicInput,
        VueSpinnerToBtn

    },
    props: {
        header: {
            type: Object,
            required: true
        },
        submitButtonTitle: {
            type: String,
            required: true
        },
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        parentIdentify: {
            type: String,
            required: false
        },
        isSubCase: {
            type: Boolean,
            required: false
        }
    },
    data() {

        return {
            isInformant: false,
            isInstructor: false,
            disableBtn: false,
            isInternalCase: null,
            isBlank: false,
            identifier: '',
            isGetDataError: false,
            files: [],
            isModalActive: false,
            isLoading: true,
            fetchData: {
                categories: [],
                instructor: [],
                states: [],
                department: [],
                priority: [{ name: this.$t('general.inputElements.priority.state.hight') }, { name: this.$t('general.inputElements.priority.state.medium') }, { name: this.$t('general.inputElements.priority.state.low') }]
            },
            fileData: {
                subjectFile: { error: false, value: '' },
                department: { error: false, value: '' },
                categories: { error: false, value: '' },
                instructor: { error: false, value: '' },
                state: { error: false, value: '' },
                priority: { error: false, value: '' },
                report: { error: false, value: '' },
            },
            elemntNeedToContinue: [],
            infoMessage: {
                isActive: false,
                icon: 'info',
                text: this.$t('general.infoMessage.saveCorrectly'),
                count: 0
            },
            dinamicInputArray: [],
            finalDataDinamic: [],
            isEditCheckDinamicArray: false
        };
    },
    mixins: [translationStateDefaultMixing, translationCategoryDefaultMixing],
    computed: {
        ...mapGetters(['getUserCompany']),
        userCompany() {
            return this.getUserCompany;
        }
    },
    watch: {
        userCompany(newValue) {
            if (newValue === '') {
                //se ha hecho logout 
                return
            }
            this.clearData()
            this.getData()
        },
        fileData: {
            handler() {
                if (this.infoMessage.isActive) {
                    this.infoMessage.count++
                    if (this.infoMessage.count === 2) {
                        this.infoMessage.isActive = false
                        this.infoMessage.count === 0
                    }
                }
            },
            deep: true
        },
        'fileData.report.value'(newValue) {
            if (!this.fileData.report.error) return
            if (newValue !== '') {
                this.fileData.report.error = false
            }
        },
        'fileData.categories.value'(newValue) {
            if (newValue && newValue?.form) {
                this.getDinamicForm(newValue.form)
            } else {
                if (this.isEdit && !this.isEditCheckDinamicArray) {
                    this.isEditCheckDinamicArray = true
                } else {
                    this.dinamicInputArray = []
                }

            }
        }

    },
    methods: {
        async getDinamicForm(data) {

            try {
                const response = await axios.get(`/forms/${data.id}`);
                this.dinamicInputArray = response.data.items
            } catch (error) {
                console.log(error);
                this.showErrorMessage()
                this.scrollToTop()
            }
        },
        scrollToTop() {
            const element = this.$refs.caseFileCreateEditLayaout;
            element.scrollIntoView({ behavior: "smooth" });
        },
        goBack() {
            window.history.back();
        },
        cleanFileDataError(fileDataName) {
            this.fileData[fileDataName].error = false
        },
        clearData() {
            this.fileData = {
                subjectFile: { error: false, value: '' },
                department: { error: false, value: '' },
                categories: { error: false, value: '' },
                instructor: { error: false, value: '' },
                state: { error: false, value: '' },
                priority: { error: false, value: '' },
                report: { error: false, value: '' },
            }
        },
        checkIsNotInternal() {
            if (this.isInternalCase === null) {
                return false
            } else if (this.isInternalCase === true) {
                return false
            } else if (this.isInternalCase === false) {
                return true
            }

        },
        addElemntNeedToContinue(array, name) {
            if (array.length === 0) {
                this.elemntNeedToContinue.push(name)
            }
        }
        ,
        handleFileUpload(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
            }
        },
        openFileInput() {
            this.$refs.fileInput.click();
        },
        eliminarArcivo(file) {
            this.files = this.files.filter(f => f !== file);
        },
        convertPriorityNameToNumber(name) {
            let number
            switch (name) {
                case this.$t('general.inputElements.priority.state.low'):
                    number = 1
                    break;
                case this.$t('general.inputElements.priority.state.medium'):
                    number = 2
                    break;
                case this.$t('general.inputElements.priority.state.hight'):
                    number = 3
                    break;
            }
            return number
        },
        showErrorMessage() {
            this.infoMessage.count = 1
            this.infoMessage.isActive = true
            this.infoMessage.icon = 'error'
            this.infoMessage.text = this.$t('general.infoMessage.generalError')
        },
        showInfoMessage() {
            this.infoMessage.count = 1
            this.infoMessage.isActive = true
            this.infoMessage.icon = 'info'
            this.infoMessage.text = this.$t('general.infoMessage.editCorrectly')
        },
        changePriorityNumberForText(number) {
            if (number === 1) {
                return this.$t('general.inputElements.priority.state.low')
            } else if (number === 2) {
                return this.$t('general.inputElements.priority.state.medium')
            } else {
                return this.$t('general.inputElements.priority.state.hight')
            }
        },
        returnIdFromCompareArray(arrayToSearch, stringToCompare) {
            let value
            arrayToSearch.forEach(element => {
                if (element.name === stringToCompare) {
                    value = element['@id']
                }
            });
            return value
        }
        ,
        async getData() {
            this.isModalActive = false;
            this.elemntNeedToContinue = [];

            try {

                if (this.isInstructor) {
                    const request1 = axios.get(`/categories?company=${this.userCompany}&itemsPerPage=200`);
                    const request2 = axios.get(`/states/?company=${this.userCompany}&itemsPerPage=200`);
                    const request3 = axios.get(`/departments/?company=${this.userCompany}&itemsPerPage=200`);

                    const responses = await Promise.all([request1, request2, request3]);


                    this.fetchData.categories = this.changeTraslationDefaultCategories(responses[0].data['hydra:member']);
                    this.fetchData.states = this.changeTraslationDefaultState(responses[1].data['hydra:member']);
                    this.fetchData.department = responses[2].data['hydra:member'];

                    if (this.fetchData.categories.length === 0 ||
                        this.fetchData.states.length === 0 ||
                        this.fetchData.department.length === 0) {

                        this.addElemntNeedToContinue(this.fetchData.categories, 'categorías');
                        this.addElemntNeedToContinue(this.fetchData.states, 'estados');
                        this.addElemntNeedToContinue(this.fetchData.department, 'departamentos');
                        this.isModalActive = true;
                    }
                } else {

                    const request1 = axios.get(`/categories?company=${this.userCompany}&itemsPerPage=200`);
                    const request2 = axios.get(`/states/?company=${this.userCompany}&itemsPerPage=200`);
                    const request3 = axios.get(`/departments/?company=${this.userCompany}&itemsPerPage=200`);
                    const request4 = axios.get(`/user_instructors?company=${this.userCompany}&itemsPerPage=200`);


                    const responses = await Promise.all([request1, request2, request3, request4]);

                    this.fetchData.categories = this.changeTraslationDefaultCategories(responses[0].data['hydra:member']);
                    this.fetchData.states = this.changeTraslationDefaultState(responses[1].data['hydra:member']);
                    this.fetchData.department = responses[2].data['hydra:member'];
                    this.fetchData.instructor = responses[3].data['hydra:member'].filter(instructor => instructor.verified);


                    if (this.fetchData.categories.length === 0 ||
                        this.fetchData.states.length === 0 ||
                        this.fetchData.department.length === 0
                        || this.fetchData.instructor.length === 0) {

                        this.addElemntNeedToContinue(this.fetchData.categories, 'categorías');
                        this.addElemntNeedToContinue(this.fetchData.states, 'estados');
                        this.addElemntNeedToContinue(this.fetchData.department, 'departamentos');
                        this.addElemntNeedToContinue(this.fetchData.instructor, 'instructores');
                        this.isModalActive = true;
                    }
                }
            } catch (error) {
                console.error('Error during GET requests:', error);
                this.isGetDataError = true;
            }
            finally {
                if (!this.isEdit) {
                    this.isLoading = false;
                }
            }
        },
        async updateFileData() {
            try {
                let data = {}

                if (this.isSubCase) {
                    const { childId } = this.$route.params;
                    this.identifier = childId
                    const response = await axios.get(`sub_case_files/${childId}`);
                    data = response.data


                } else {
                    const { id } = this.$route.params;
                    this.identifier = id
                    const response = await axios.get(`case_files/${id}`);
                    data = response.data
                }


                this.isInternalCase = data.internalCaseFile


                this.fileData.subjectFile.value = data.subject;
                if (data.instructor) {
                    this.fileData.instructor.value = [{ name: data.instructor.name, '@id': data.instructor['@id'] }]

                } else {
                    this.fileData.instructor.value = ''
                }




                this.fileData.categories.value = { name: this.changeTraslationDefaultCategories([data.category], false)[0].name };
                this.fileData.state.value = { name: this.changeTraslationDefaultState([data.state])[0].name }
                this.fileData.department.value = { name: data.department.name }
                this.fileData.priority.value = { name: this.changePriorityNumberForText(data.priority) }
                this.fileData.report.value = data.description
                this.dinamicInputArray = data.formData

            } catch (error) {
                this.isGetDataError = true;
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        },
        async editCaseFile() {
            this.disableBtn = true
            if (this.checkAllFieldsFilled()) {
                this.disableBtn = false
                this.isBlank = true
                setTimeout(() => {
                    this.isBlank = false
                }, 1500)
                this.$nextTick(() => {
                    const element = this.$refs.isBlankMessage
                    // Aseguramos que el DOM se haya actualizado
                    element.scrollIntoView({ behavior: "smooth" });
                });
                return
            }
            this.$refs.spinnerActionBtn.activeSpinner()
            let url = ''
            if (this.isSubCase) {
                url = `/sub_case_files/${this.identifier}`
            } else {
                url = `case_files/${this.identifier}`

            }
            const dataPatch = this.formatedData()


            try {
                await axios.patch(url, dataPatch, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });


                this.showInfoMessage()
                this.finalDataDinamic = []

            } catch (error) {
                this.showErrorMessage()
                console.error('Error:', error.response ? error.response.data : error.message);
            }
            finally {
                this.scrollToTop()
                this.disableBtn = false
                this.$refs.spinnerActionBtn.desactiveSpinner()
            }
        },
        async loadData() {
            this.isLoading = true;
            try {
                if (!this.isInformant) {
                    await this.getData();
                }

                if (this.isEdit) {
                    await this.updateFileData();
                }
            }
            finally {
                this.isLoading = false;
            }
        },
        formatedData() {
            const dataPost = {}

            if (!this.isInformant) {



                dataPost.subject = this.fileData.subjectFile.value
                dataPost.category = this.fileData.categories.value['@id']
                dataPost.priority = this.convertPriorityNameToNumber(this.fileData.priority.value.name)

                dataPost.state = this.fileData.state.value['@id']
                dataPost.department = this.fileData.department.value['@id']
                dataPost.description = this.fileData.report.value
                dataPost.company = `/api/v1/companies/${this.userCompany}`
                dataPost.formData = this.finalDataDinamic



                if (this.isInstructor) {
                    dataPost.instructor = `/api/v1/user_instructors/${this.$store.getters.getUser.id}`

                    if (!this.isEdit) {
                        //el instructor no TOCA la lista en la EDICIÓN solo En la Creación
                        dataPost.instructorsList = [`/api/v1/user_instructors/${this.$store.getters.getUser.id}`]
                    }

                } else {
                    //para los ususarios que NO son INSTRUCTORES

                    //si se elige 1 instructor se asigna el expediente directamente
                    //si se elige + de 1 no se asigna instructor al expediente

                    if (this.fileData.instructor.value.length === 1) {
                        dataPost.instructor = this.fileData.instructor.value[0]['@id']
                        dataPost.instructorsList = [this.fileData.instructor.value[0]['@id']]


                    } else {
                        dataPost.instructor = null
                        const intructorArray = this.fileData.instructor.value.map(element => element['@id'])
                        dataPost.instructorsList = intructorArray
                    }



                    //TO DO hay que hacer algo con el array de files

                }



            } else {
                //si es informante solo se modifica: 
                dataPost.subject = this.fileData.subjectFile.value
                dataPost.formData = this.finalDataDinamic
                dataPost.description = this.fileData.report.value
            }

            if (this.isSubCase) {
                dataPost.parentCaseFile = `/api/v1/case_files/${this.parentIdentify}`
                dataPost.formData = this.finalDataDinamic
                dataPost.description = this.fileData.report.value
            }

            //TO DO hay que hacer algo con el array de files

            return dataPost

        },
        checkAllFieldsFilled() {
            this.finalDataDinamic = []
            let isBlanck = false
            //Comprobamos que todos los campos están rellenados
            for (let key in this.fileData) {
                if (this.fileData[key].value === '' || this.fileData[key].value.length === 0) {
                    if (key === 'instructor' && (this.isInstructor || this.isInformant)) {
                        //no hacemos nada
                    }
                    else {
                        isBlanck = true
                        this.fileData[key].error = true
                    }
                }
            }
            //comprobamos los formularios dinamicos
            this.dinamicInputArray.forEach((element) => {
                let refValue = 'DinamicInput' + element.id
                if (this.$refs[refValue]) {
                    let finalValor = this.$refs[refValue][0].checkError();
                    if (finalValor === 'errorValue') {
                        isBlanck = true

                    } else {

                        if (element.type === 'Selector') {
                            finalValor = finalValor.name
                            if (!finalValor) {
                                finalValor = ''
                            }
                        }
                        this.finalDataDinamic = [...this.finalDataDinamic, { ...element, dataValue: finalValor }]
                    }
                }
            });
            return isBlanck
        },
        async addCaseFile() {
            this.disableBtn = true
            this.infoMessage.count = 0
            this.infoMessage.isActive = false

            if (this.checkAllFieldsFilled()) {
                this.disableBtn = false
                this.isBlank = true
                setTimeout(() => {
                    this.isBlank = false
                }, 1500)


                this.$nextTick(() => {
                    const element = this.$refs.isBlankMessage
                    // Aseguramos que el DOM se haya actualizado
                    element.scrollIntoView({ behavior: "smooth" });
                });
                return
            }
            this.$refs.spinnerActionBtn.activeSpinner()

            const dataPost = this.formatedData()

            try {
                if (this.isSubCase) {
                    await axios.post(`/sub_case_files`, dataPost);
                } else {
                    await axios.post(`/case_files`, dataPost);
                }

                this.clearData()
                this.infoMessage.isActive = true
                this.finalDataDinamic = []
            } catch (error) {
                this.showErrorMessage()
                console.log(error)
            }
            finally {
                this.disableBtn = false
                this.scrollToTop()
                this.$refs.spinnerActionBtn.desactiveSpinner()
            }
        }

    },
    created() {
        if (this.$store.getters.getRoles.includes('ROLE_INSTRUCTOR') && !this.$store.getters.getRoles.includes('ROLE_SUPERVISOR')) {
            this.isInstructor = true
        }
        else if (this.$store.getters.getRoles.includes('ROLE_INFORMANT')) {
            this.isInformant = true
        }

        this.loadData();
    },
};
</script>



<style scoped lang="scss">
.error {
    border-color: #C74D4D !important;
}

.info-message {
    margin-top: 20px;
}

.active {
    opacity: 1;
}

.notActive {
    opacity: 0;
}

.error-message {
    text-align: left;
    color: #C74D4D;
    padding-top: 6px;
    font-size: 14px;
    font-family: 'InterRegular';
}

.modal {

    margin-top: 20px;
    display: flex;
    justify-content: center;
    height: 100vh;

    width: 100%;
    background-color: rgba(22, 63, 73, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal div {
    height: fit-content;
    max-width: 497px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
        0 6px 20px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    border-radius: 8px;
    border: none;
    padding: 24px;

    h1 {
        color: #0E0E0F;
        font-size: 18px;
        line-height: 24.2px;
        text-align: left;

    }

    li {
        color: #C74D4D;
        list-style: none;
        text-align: left;
        font-size: 16px;
        font-family: 'InterRegular', sans-serif;
    }

    @media (max-width: 400px) {
        h1 {
            font-size: 16px;
        }

        li {
            font-size: 14px;
        }
    }


}

.get-data-error {
    margin-top: 20px;
    height: 100vh;

    @media (max-width: 768px) {
        padding: 24px;
    }

}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
}




.files {
    position: relative;
    width: calc(100% - 24px);
    margin: 0 10px 0 16px;
    border-top: none;
    border-radius: 0 0 7.03px 7.03px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    overflow-y: scroll;
    max-height: 75px;

}


.template {
    position: relative;
}


form {
    background-color: #fff;
    margin-top: 20px;
    border-radius: 8px;
    padding: 32px 32px 141px 24px;
}

.grid {
    display: grid;
    grid-template-columns: minmax(200px, 30%) minmax(200px, 30%) minmax(300px, 39%);
    grid-template-rows: 90px;
    grid-auto-rows: minmax(90px, auto);
    gap: 24px 0;
}

.grid>* {
    grid-row: auto;
}


.colum1 {
    grid-column: 1 / 2;
    margin-right: 31px;
}

.colum2 {
    grid-column: 2 / 3;
    margin-right: 48px;
}



.estado-prioridad {
    display: flex;

    label:first-child {
        width: 100%;
    }
}

.priority {
    width: 130px;
}

input,
button,
.pointer {
    cursor: pointer;
}

p {
    text-align: left;
    margin-bottom: 8px;
    color: #707C7E;
    font-size: 14px;
    font-family: 'InterMedium';

}



.colum3 {
    grid-column: 3 / 4;
    grid-row: 1 / 4;
    display: flex;
    flex-direction: column;


    .textarea-container {
        flex-grow: 1;
        width: 100%;
        border: 1.25px solid #B3BBBC;
        border-radius: 8px;
        padding: 14px 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .textarea-container:focus-within {
        border: 2px solid #0E0E0F;
    }

    textarea {
        background-color: transparent;
        width: 100%;
        padding: 4px 15px;
        color: #0E0E0F;
        font-size: 16px;
        font-family: 'InterRegular';
        line-height: 20.8px;
        border: none;
        border-radius: 8px;
        resize: none;
        outline: none;
        height: 80%;
    }
}

.colum4 {
    grid-column: 3 / 4;
    grid-row: 4 / 5;


    button {
        height: 48px;
        font-family: 'InterMedium';
        font-weight: 500;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        gap: 5px;

        button,
        .file {
            border: none;
            background-color: #CBDEE2;
            border-radius: 8px;
            color: #163F49;
            line-height: 19.36px;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;

            label {
                padding: 14px;
            }
        }

        button {
            padding: 14px;
        }
    }

    .file,
    .file>label {
        display: flex;
        align-items: center;
    }

    .file>label {
        gap: 8px;
        color: #163F49;
        line-height: 19.36px;
        font-size: 16px;
        font-family: 'InterMedium';
        font-weight: 500;
    }

    .fileTextHiden {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        border: 0;
    }

    .fileTextHiden:focus+label {
        border: 2px solid #0E0E0F !important;
        /* Add an orange line on focus */
        border-radius: 8px;
    }
}

.disableBtn {
    cursor: wait !important;
}

.sumbit-button-container {

    display: flex;
    justify-content: flex-end;
    align-items: end;
    flex-direction: column;
    gap: 20px;



    button {
        background-color: #163F49;
        border-radius: 4px;
        line-height: 21.78px;
        text-align: center;
        width: 305px;
        border: none;
        color: #F9FBFC;
        height: 48px;
        font-family: 'InterMedium';
        font-weight: 500;
    }
}


@media (max-width: 1285px) {

    .grid {
        display: grid;
        grid-template-columns: minmax(200px, 50%) minmax(200px, 50%);
        grid-template-rows: auto;
        grid-auto-rows: auto;
    }


    .colum1 {
        grid-column: 1 / 2;
        margin-right: 31px;
    }



    .colum2 {
        grid-column: 2 / 3;


    }


    .colum3 {
        grid-column: 1 / -1;
        grid-row: 5 / 6;
        display: flex;
        flex-direction: column;

        .textarea-container {
            flex-grow: 1;
            width: 100%;
            border: 1.25px solid #B3BBBC;
            border-radius: 8px;
            padding: 14px 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        height: 275px;

        .textarea-container:focus-within {
            border: 2px solid #0E0E0F;
        }

        textarea {
            width: 100%;
            padding: 4px 15px;
            color: #0E0E0F;
            font-size: 16px;
            font-family: 'InterRegular';
            line-height: 20.8px;
            border: none;
            border-radius: 8px;
            resize: none;
            outline: none;
            height: 80%;
        }


    }

    .colum4 {
        grid-column: 1 / -1;
        grid-row: 6 / 7;
        padding-top: 24.100px;
    }


}


@media (max-width: 1200px) {


    .title {

        margin-top: 25.49px;
    }

    .grid {
        margin-top: 32px;
    }
}

@media (max-width: 800px) {

    .grid {
        display: flex;
        flex-direction: column;
        padding: 44px 24px 64px 24px;
        margin-top: 16px;


    }

    .colum1,
    .colum2,
    .colum3,
    .colum4 {
        margin-right: 0;
    }

    .estado-prioridad {
        display: flex;
        flex-direction: column;
        gap: 24px;


    }

    .colum4 {
        .sumbit-button-container {
            margin-top: 38px;
            display: flex;
            justify-content: flex-end;

            button {
                width: 100%;

            }
        }
    }
}



@media (max-width: 768px) {
    .title {
        padding-left: 24px;
    }

    .grid {
        margin-left: 12px;
        padding-left: 6px;
    }

    .info-message {
        margin-top: 20px;
        margin-left: 12px;
    }

    form {
        padding-inline: 0;
    }

}


@media (max-width: 450px) {
    .colum4 {
        .button-container {

            gap: 8px;
            flex-direction: column;
            justify-content: center;



            input,
            button,
            label {
                font-size: 12px !important;

            }

            .file {
                label {
                    margin-inline: auto;
                }
            }
        }
    }

}
</style>