<template>



  <!-- Modal del añadir y editar -->

  <!-- Cuando pongas más de 1 instructor, cambiar isSelectInstructor="true"-->
  <ModalAddFile v-if="isTaskModal" :isDisableBtn="isDisableBtn" ref="taskModal" :cleanInforMessage="cleanInforMessage"
    :infoMessageData="infoMessageData" @toggleModalActive="toggleTaskModal" :selectData="selectData"
    :isSelectModal="true" :isSelectInstructor="false"
    :title="isEditActive ? this.$t('caseFile.detailsCaseFile.task.create-edit.titleEdit') : this.$t('caseFile.detailsCaseFile.task.create-edit.titleCreate')"
    :btnTitle="isEditActive ? this.$t('caseFile.detailsCaseFile.task.create-edit.buttonEdit') : this.$t('caseFile.detailsCaseFile.task.create-edit.buttonCreate')"
    :placeholderText="this.$t('caseFile.detailsCaseFile.task.create-edit.placeholderDescription')"
    @handleButton="handleButton" :isEditActive="isEditActive" :selectedEditData="selectedEditData"
    :isModalLoader="isModalLoaderEdit" />

  <ModalVisualice v-if="isModalNoteVisualice" @toggleModalActive="toggleModalVisualice()" :elemntData="taskElement"
    :isModalLoader="isModalLoader" :infoMessageData="infoMessageDataVisualice" :showPersonInCharge="false" />

  <div ref="task-father" class="task-list">
    <div v-if="isLoader" class="loader">
      <LoaderComponent />
    </div>


    <InfoMessage :isActive="errorMessage.isError" color="#C74D4D" :text="errorMessage.text" icon="error" />
    <div v-show="!isLoader" class="title-filter">

      <div class="title-button">
        <h2>{{ this.$t('caseFile.detailsCaseFile.task.title') }}</h2>
        <button @click="toggleTaskModal"><img src="@/assets/icons/add-border.svg" /></button>
      </div>

      <div @click="toggleDropdown" class="filter-select" ref="dropdown">
        <p>{{ this.$t('caseFile.detailsCaseFile.task.filterTittle') }}</p>
        <button>{{ filterseletecOption }} <img src="../../assets/icons/arrow-down-task.svg" alt="arrow down"></button>
        <div v-if="isOpen" class="filter-options">
          <DropdownMenu @clickedAction="filterTask" :isOpen="isOpen" :links="optionFilterTasks"
            :estilos="{ top: '30px', right: '-30px' }" />
        </div>
      </div>
    </div>


    <div v-show="!isLoader" class="task-list-content" ref="taskScroll">
      <div class="task-item" v-for="task in caseFileTaskWithSelect" :key="task.id">
        <div class="task-item-title-date">
          <p>{{ task.createdAt }}</p>
          <h3 :title="task.description">
            {{ task.description }}
          </h3>
          <div class="task-item-state-all-space">
            <div class="task-item-state">
              <button
                :class="[{ 'first': task.state === 0 }, { 'second': task.state === 1 }, { 'thrid': task.state === 2 }]"
                @click="openDropwdown(task.id, task.isSelect)">{{ this.reverseFormattedState(task.state) }}</button>
            </div>
            <div class="dropdown-task-box" :ref="'dropwdownTask' + task.id">
              <DropdownMenu @toggleModalVisualice="activeModal(task.id)" @giveMeText="(e) => changeState(e, task.id)"
                @toggleModalEdit="toggleModalEdit(task.id)" :isDropdownMenuOnly="true" :isOpen="task.isSelect"
                @toggleModalDelete="toggleModalDelete(task.id)"
                :estilos="{ top: `${dropdownPositionY}px`, left: `${dropdownPositionX}px` }" :links="[
                  { text: this.$t('caseFile.detailsCaseFile.task.types.completed'), data: { action: 'giveMeText' } },
                  { text: this.$t('caseFile.detailsCaseFile.task.types.underReview'), data: { action: 'giveMeText' } },
                  { text: this.$t('caseFile.detailsCaseFile.task.types.pending'), data: { action: 'giveMeText' } },
                  { text: this.$t('general.dropdownOptions.visualice'), data: { action: 'toggleModalVisualice' } },
                  { text: this.$t('general.dropdownOptions.edit'), data: { action: 'toggleModalEdit' } },
                  { text: this.$t('general.dropdownOptions.delete'), data: { action: 'toggleModalDelete' }, estilos: { color: '#C74D4D' } },
                ]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <DeleteModalTable v-if="deleteModalInfo.isDeleteActive" option="simple"
    :lastSingularId="deleteModalInfo.deleteIndetify" @toggleModal="toggleModalDelete" path="/tasks"
    :deleteModalText="deleteModalInfo.deleteModalText" @trigger-fetch="getTasks()" />


</template>

<script>

import DeleteModalTable from "../categories/DeleteModalTable.vue";
import DropdownMenu from "../general/DropdownMenu.vue";
import ModalAddFile from "../general/ModalAddFile.vue";
import axios from "axios";
import InfoMessage from "../general/InfoMessage.vue";
import LoaderComponent from "../general/LoaderComponent.vue";
import ModalVisualice from "../general/ModalVisualice.vue";


export default {
  name: "TaskListWidget",
  components: {
    DropdownMenu,
    ModalAddFile,
    DeleteModalTable,
    InfoMessage,
    LoaderComponent,
    ModalVisualice
  },

  props: {

    caseFile: {
      type: Object,
      required: true
    },
    caseFilesInstructor: {
      type: Array
    },
    activeTask: Boolean
  },
  emits: ['toggleModalDelete'],

  data() {
    return {
      isInstructor: false,
      userId: this.$store.getters.getUser.id,
      isDisableBtn: false,
      editId: '',
      infoMessageDataVisualice: {
        isActive: false,
        isError: false,
        text: this.$t('general.infoMessage.generalError'),
      },
      isModalLoaderEdit: false,
      isModalLoader: false,
      taskElement: {},
      isModalNoteVisualice: false,
      isEditActive: false,
      selectedEditData: {
        option: '',
        instructor: ''
      },
      errorMessage: {
        isError: false,
        text: ''
      },
      isLoader: false,
      deleteModalInfo: {
        isDeleteActive: false,
        deleteIndetify: '',
        deleteModalText: {
          title: this.$t('caseFile.detailsCaseFile.task.deleteModal.title'),
          textSingular: this.$t('caseFile.detailsCaseFile.task.deleteModal.textSingular'),
          errorSingular: this.$t('caseFile.detailsCaseFile.task.deleteModal.errorSingular'),
        },
      },
      caseFileId: '',
      isOpen: false,
      checkDropdownTask: 0,
      filterseletecOption: this.$t('caseFile.detailsCaseFile.task.types.all'),
      optionFilterTasks: [
        { text: this.$t('caseFile.detailsCaseFile.task.types.all'), data: { name: 'Todas' } },
        { text: this.$t('caseFile.detailsCaseFile.task.types.pending'), data: { name: 0 } },
        { text: this.$t('caseFile.detailsCaseFile.task.types.underReview'), data: { name: 1 } },
        { text: this.$t('caseFile.detailsCaseFile.task.types.completed'), data: { name: 2 } },
      ],
      selectData: {
        opciones: [
          { name: this.$t('caseFile.detailsCaseFile.task.types.pending') }, { name: this.$t('caseFile.detailsCaseFile.task.types.underReview') }, { name: this.$t('caseFile.detailsCaseFile.task.types.completed') }
        ],
        intructores: this.caseFilesInstructor
      },
      isTaskModal: false,
      isTaskDropdownOpen: false,
      infoMessageData: {
        isActive: false,
        isError: false,
        text: this.$t('general.infoMessage.generalError'),
      },
      caseFileTaskWithSelect: [],
      caseFileTasks: [],
      dropdownPositionX: 0,
      dropdownPositionY: 0,

    };
  },



  methods: {
    handleButton(data) {
      this.isDisableBtn = true
      this.$refs.taskModal.activeSpinner()
      if (this.isEditActive) {
        this.editTask(data)
      } else {
        this.saveTask(data)
      }
    },
    async editTask(data) {
      let url = `/tasks/${this.editId}`

      let dataPatch = {}

      if (this.isInstructor) {
        dataPatch = { state: this.formatedState(data.state.name), description: data.description }
      } else {
        dataPatch = { state: this.formatedState(data.state.name), description: data.description }
      }
      /*
      para más de un INSTRUCTOR
      if (this.isInstructor) {
        dataPatch = { state: this.formatedState(data.state.name), description: data.description }
      } else {
        dataPatch = { state: this.formatedState(data.state.name), description: data.description, instructor: `/api/v1/user_instructors/${data.instructor.id}` }
      }
        */
      try {
        await axios.patch(url, dataPatch, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        this.infoMessageData.isActive = true
        this.infoMessageData.text = this.$t('general.infoMessage.editCorrectly')
        this.$refs.taskModal.setCount(3)

      } catch (error) {
        console.log(error)
        this.infoMessageData.isActive = true
        this.infoMessageData.text = this.$t('general.infoMessage.generalError')
        this.infoMessageData.isError = true
        this.$refs.taskModal.setCount(3)

      }
      finally {
        this.getTasks(this.caseFileId)
        this.isDisableBtn = false
        this.$refs.taskModal.desactiveSpinner()
      }
    },
    activeModal(id) {
      this.isModalLoader = true
      this.toggleModalVisualice()
      axios.get(`/tasks/${id}`)
        .then(res => {
          console.log(res)
          this.taskElement = {
            name: this.reverseFormattedState(res.data.state),
            createdAt: res.data.createdAt,
            description: res.data.description,
            // para más de 1 instructor 
            //createdPerson: res.data.instructor.name
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isModalLoader = false
        })
    },
    calculateDistance(id) {

      const dropdownRef = this.$refs['dropwdownTask' + id][0]
      const dropdownRect = dropdownRef.getBoundingClientRect();
      const taskContainer = this.$refs['task-father']
      const taskContainerRect = taskContainer.getBoundingClientRect()

      this.dropdownPositionX = dropdownRect.left - taskContainerRect.left + 50
      this.dropdownPositionY = dropdownRect.top - taskContainerRect.top - 10

      // si es movil, necesitamos quitarle px en left porque se sale de la pantalla al ser una sola columna
      if (window.innerWidth <= 768) {
        this.dropdownPositionX -= 20
      }
      if (window.innerWidth <= 350) {
        this.dropdownPositionX -= 30
      }

    },
    toggleModalVisualice() {
      this.isModalNoteVisualice = !this.isModalNoteVisualice
      if (this.isModalNoteVisualice) { this.closeAllDropdownTask() }
    },
    cleanInforMessage() {
      this.infoMessageData = {
        isActive: false,
        isError: false,
        text: this.$t('general.infoMessage.generalError')
      }
    },
    toggleModalDelete(id) {
      this.deleteModalInfo.isDeleteActive = !this.deleteModalInfo.isDeleteActive
      if (this.deleteModalInfo.isDeleteActive) { this.closeAllDropdownTask() }
      this.deleteModalInfo.deleteIndetify = id
    },

    async changeState(futureState, ide) {
      this.errorMessage.isError = false
      this.caseFileTaskWithSelect = this.caseFileTaskWithSelect.map(task => task.id === ide ? { ...task, state: this.formatedState(futureState), isSelect: false } : { ...task })
      this.caseFileTasks = this.caseFileTaskWithSelect.map(task => task.id === ide ? { ...task, state: this.formatedState(futureState) } : { ...task })

      let url = `/tasks/${ide}`
      let dataPatch = { state: this.formatedState(futureState) }
      try {
        await axios.patch(url, dataPatch, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

      } catch (error) {
        console.log(error)
        this.errorMessage.isError = true
        this.errorMessage.text = this.$t('general.infoMessage.noUpdateChange')

      }
      finally {
        this.getTasks(this.caseFileId)
      }

    },

    openDropwdown(id, isSelect) {


      if (isSelect) {
        //para poder hacer el toggle
        this.closeAllDropdownTask()
        return
      }

      //para abrirla segun la posicion y cerrar las demas
      this.closeAllDropdownTask()
      this.caseFileTaskWithSelect = this.caseFileTaskWithSelect.map(task => {
        if (task.id === id) {
          this.calculateDistance(id)
          this.isTaskDropdownOpen = true
          this.checkDropdownTask = 0
          return { ...task, isSelect: true }
        } else {
          return task
        }
      }
      )

    },
    reverseFormattedState(number) {
      if (number === 0) {
        return this.$t('caseFile.detailsCaseFile.task.types.pending');
      } else if (number === 1) {
        return this.$t('caseFile.detailsCaseFile.task.types.underReview');
      } else if (number === 2) {
        return this.$t('caseFile.detailsCaseFile.task.types.completed');
      }
    },
    formatedState(word) {
      if (word === this.$t('caseFile.detailsCaseFile.task.types.pending')) {
        return 0
      } else if (word === this.$t('caseFile.detailsCaseFile.task.types.underReview')) {
        return 1
      } else if (word === this.$t('caseFile.detailsCaseFile.task.types.completed')) {
        return 2
      }
    },
    async saveTask(data) {

      //para más de 1 instructor 
      // const { description, state, instructor } = data
      const { description, state } = data
      const dataTask = {
        description: description,
        state: this.formatedState(state.name),
        caseFile: this.caseFile['@id']
      }

      /*
      para más de 1 instructor
      if(this.isInstructor) {
        dataTask.instructor =  `/api/v1/user_instructors/${this.userId}`
      } else{
        dataTask.instructor = `/api/v1/user_instructors/${instructor.id}`
      }
        */

      try {
        await axios.post(`/tasks`, dataTask);
        this.infoMessageData.isActive = true
        this.infoMessageData.text = this.$t('general.infoMessage.saveCorrectly')
        this.$refs.taskModal.cleanAll()
        //actualizamos las tareas
        this.getTasks(this.caseFileId)

      } catch (error) {

        this.infoMessageData.isActive = true
        this.infoMessageData.text = this.$t('general.infoMessage.generalError')
        this.infoMessageData.isError = true
        this.$refs.taskModal.setCount(3)

        console.log(error)
      }
      finally {
        this.isDisableBtn = false
        this.$refs.taskModal.desactiveSpinner()
      }
    },
    toggleTaskModal() {
      this.isTaskModal = !this.isTaskModal
      if (this.isTaskModal) { this.closeAllDropdownTask() }
      if (!this.isTaskModal) {
        this.isEditActive = false
      }
    },
    toggleModalEdit(id) {
      this.isModalLoaderEdit = true
      this.editId = id
      this.closeAllDropdownTask()

      axios.get(`/tasks/${id}`).then(res => this.selectedEditData = {
        description: res.data.description,
        option: { name: this.reverseFormattedState(res.data.state) },
        // para más de 1 instructor
        //  instructor: { name: res.data.instructor.name, id: res.data.instructor.id },
      })
        .catch(error => {
          console.log(error)
          this.infoMessageData.isActive = true
          this.infoMessageData.text = this.$t('general.infoMessage.generalError')
          this.infoMessageData.isError = true
          this.$refs.taskModal.setCount(3)
        })
        .finally(() => {
          this.isModalLoaderEdit = false
        })

      this.isEditActive = true
      this.isTaskModal = true
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    closeAllDropdownTask() {
      this.caseFileTaskWithSelect = this.caseFileTaskWithSelect.map(task => ({ ...task, isSelect: false }))
    },
    handleClickOutside(event) {
      //solo para los dropwmenu de list ya que se ejecutan diferente
      if (this.isTaskDropdownOpen && !event.target.closest('.dropdown-task-box')) {

        //esto se pone para asegurarnos que cuando se clica el boton de pendiente o en revision no se cierre automaticamente el dropdown
        if (this.checkDropdownTask !== 0) {
          this.checkDropdownTask = 0
          this.isTaskDropdownOpen = false
          //cerramos todos
          this.closeAllDropdownTask()

        }
        this.checkDropdownTask = 1

      }

      // dropwmenu de filtrar
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.closeDropdown();
      }
    },
    filterTask(data) {
      if (data.data.name === 'Todas') {
        this.filterseletecOption = 'Todas'
      } else {
        this.filterseletecOption = this.reverseFormattedState(data.data.name)
      }

      if (data.data.name === 'Todas') {
        this.caseFileTaskWithSelect = this.caseFileTasks.map(task => ({ ...task, isSelect: false }))
      } else {
        this.caseFileTaskWithSelect = this.caseFileTasks.filter(task => task.state === data.data.name).map(task => ({ ...task, isSelect: false }))
      }

    },
    handleScroll() {
      //ocultar los dropdwon de notas cuando se haga scroll ya que debido al overflow
      //Para que solo se ejecute quitar los dropdown cuando este abierto uno y se haga scroll 
      if (!this.isTaskDropdownOpen) return
      this.closeAllDropdownTask()
      this.checkDropdownTask = 0
      this.isTaskDropdownOpen = false
    },
    getTasks(id) {
      if (id) {
        this.caseFileId = id
      }

      axios.get(`/tasks?casefile=${this.caseFileId}&itemsPerPage=200`)
        .then(res => {
          const data = res.data['hydra:member']
          this.caseFileTasks = data.map(task => ({ ...task, state: task.state, createdAt: this.formatedDate(task.createdAt) }))
          this.caseFileTaskWithSelect = data.map(task => ({ ...task, isSelect: false, state: task.state, createdAt: this.formatedDate(task.createdAt) }))
        })
        .catch(err => {
          console.log(err)
          this.errorMessage.text = this.$t('general.infoMessage.GetError')
          this.errorMessage.isError = true
        })
        .finally(() => {
          this.isLoader = false
        })
    },
    formatedDate(fechaISO) {
      // Crear un objeto Date a partir de la cadena de fecha ISO 8601
      const fecha = new Date(fechaISO);

      // Extraer componentes de la fecha en la zona horaria local
      const dia = fecha.getDate().toString().padStart(2, '0'); // Día
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Mes (se suma 1 porque los meses en JavaScript son 0-indexados)
      const año = fecha.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

      // Formatear la fecha y la hora en el formato deseado
      const fechaFormateada = `${dia}/${mes}/${año}`;

      return fechaFormateada;

    }
  },

  watch: {
    $route() {
      this.closeDropdown();
    },
    caseFilesInstructor(newValue) {
      this.selectData.intructores = newValue
    },
    activeTask(){
      this.getTasks(this.caseFile.id)
    }
  },
  mounted() {
    this.isLoader = true
    document.addEventListener("click", this.handleClickOutside);
    this.$refs.taskScroll.addEventListener('scroll', this.handleScroll)
    if (this.$store.getters.getRoles.includes('ROLE_INSTRUCTOR')) {
      this.isInstructor = true
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
    this.$refs.taskScroll.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  justify-content: center;
}

.task-list {
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 18px 18px 24px 24px;
  display: flex;
  flex-direction: column;
  position: relative;

  .title-filter {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7f0f2;

    .title-button {
      display: inline-flex;

      h2 {
        margin-right: 10px;
        color: #0e0e0f;
        font-size: 16px;
        font-family: "InterMedium", sans-serif;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        width: 28px;
        font-weight: 600;
      }
    }

    .filter-select {

      display: inline-flex;
      align-items: center;
      justify-content: end;
      cursor: pointer;


      p,
      button {
        margin: 0;
        font-family: "InterRegular", sans-serif;
        color: #707c7e;
        font-size: 12px;
        background-color: transparent;
        border: none;
      }

      button {
        font-family: 'InterMedium', sans-serif;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
      }
    }
  }

  .task-list-content {
    width: 100%;
    overflow-y: auto;
    max-height: 250px;
    padding-right: 10px;

    .task-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e7f0f2;
      padding: 9px 0;
      width: 100%;

      .task-item-title-date {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 20px;
        width: 100%;

        h3 {
          font-size: 12px;
          font-family: "InterSemibold", sans-serif;
          font-weight: 600;
          color: #163f49;
          text-align: left;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &>p {
          font-size: 12px;
          font-family: "InterRegular", sans-serif;
          color: #163f49;
          margin: 0 0 0 0;
        }
      }
    }
  }
}


.task-item-state-all-space {
  margin-left: auto;
  min-width: fit-content;

}

.task-item-state {


  .first {
    color: #E7885F;
    border: 1px solid #E7885F;
  }

  .second {
    border: 1px solid #94A7AA;
    color: #94A7AA;
  }

  .thrid {
    border: 1px solid #D9E7EA;
    color: #D9E7EA;
  }

  button {
    margin: 0;
    font-size: 12px;
    font-family: 'InterMedium';
    text-align: center;
    background-color: transparent;
    padding: 3px 16px;
    border-radius: 17px;
    min-width: fit-content;
    cursor: pointer;

    @media (max-width: 350px) {
      font-size: 8px;
    }
  }
}


@media (max-width: 550px) {
  .task-list {
    .task-list-content {
      .task-item {
        .task-item-title-date {
          display: grid;
          grid-template-columns: 1fr 1fr;

          &>p {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            text-align: left;
            font-size: 14px;
          }

          h3 {
            grid-column: 1 / -1;
            grid-row: 2 / 3;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            font-family: 'InterRegular';
            margin: 0;
          }

          .task-item-state-all-space {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
          }


        }

      }
    }
  }

}


@media (max-width: 300px) {
  .task-list {

    .task-list-content {
      max-height: 210px;
    }


    .title-filter {
      flex-direction: column;
      padding-bottom: 10px;
      gap: 10px;
    }
  }

}
</style>
