<template>
    <TableMenuLayaout :goBack="goBack" :tableOptions="tableOptions" :descriptionName="descriptionName" :dbUrl="dbUrl"
        :searchOption="false" :breadcrumbs="breadcrumbs" :root="false" getPath="state"
        :tableInfoMessage="tableInfoMessage" :deleteModalText="deleteModalText" />
</template>

<script>
import TableMenuLayaout from "@/components/general/TableMenuLayaout.vue"
export default {
    name: 'StateComponent',
    components: { TableMenuLayaout },
    data() {
        return {
            tableOptions: {
                chekbox: true,
                arrow: true,
                columnTitle: [{ title: this.$t('general.tableColumTitles.state'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.state'), isUp: false }, 
                { title: this.$t('general.tableColumTitles.order'), image: require('@/assets/icons/arrow_filter.svg'), alt: this.$t('general.tableColumTitles.order'), isUp: false }
                ],
                options: true,
                //Tiene que hacer match con cateogias
                mainRowName: ['name', 'stateOrder'],
                titleDetailsRowName: [this.$t('general.tableColumTitles.description.title')],
                //Tiene que hacer match con cateogias
                detailsRowName: ['description']
            },
            descriptionName: {
                title: this.$t('caseFile.state.showState.title'),
                buttonAddName: this.$t('caseFile.state.showState.createButton')
            },
            dbUrl: {
                path: "/states",
                pathToCreate: "/expedientes/estados/crear-estado",
                pathToEdit: "/expedientes/estados/editar-estados"
            },
            breadcrumbs: [{ label: this.$t('caseFile.state.showState.breadcrumbs.first'), to: '/expedientes' }, { label: this.$t('caseFile.state.showState.breadcrumbs.second'), to: '/expedientes/estados' }],
            tableInfoMessage: {
                notCreate: this.$t('caseFile.state.noCreateState'),
                notSearch: this.$t('general.search.noState')
            },
            deleteModalText: {
                title: this.$t('caseFile.state.showState.deleteModal.title'),
                namePlural: this.$t('caseFile.state.showState.deleteModal.plurarText'),
                textSingular: this.$t('caseFile.state.showState.deleteModal.textSingular'),
                errorSingular: this.$t('caseFile.state.showState.deleteModal.errorSingular'),
                errorPlural: this.$t('caseFile.state.showState.deleteModal.errorPlural'),
            }
        }
    },
    methods: {
        goBack() {
            window.history.back();
        }
    },
}

</script>
