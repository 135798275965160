<template>
    <div :style="styleGeneralDiv" class="custom-select__container">
        <label v-if="this.label !== ''" :for="this.id" class="container__label">{{ this.label }}</label>
        <div :style="styleCustomSelect" class="custom-select">
            <select :style="styleSelect" :disabled="disabled" :name="name" :id="id" v-model="this.selected"
                :required="this.required" :class="{ 'error-select': this.error, 'disable': disabled }">
                <option v-if="this.placeholder !== ''" selected disabled value="">{{ this.placeholder }}</option>
                <option v-for="(option, index) in options" :value="option.value" :key="index">
                    {{ option.label }}
                </option>
            </select>
            <div class="icon-container">

                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">

                </svg>
            </div>
        </div>
        <span v-if="this.error" class="error">{{ this.errorMessage }}</span>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'SelectFormComponent',
    props: {
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        optionSelected: {
            type: String,
            default: ''
        },
        error: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        vModel: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        styleCustomSelect: {
            type: Object,
        },
        styleSelect: {
            type: Object
        },
        styleGeneralDiv: {
            type: Object
        }
    },
    data() {
        return {
            selected: '',
        }
    },
    created() {
        this.selected = this.optionSelected;
    },
    emits: ['optionChanged'],
    computed: {
        ...mapGetters(['getUserCompanyDisable']),
        userCompanyDisable() {
            return this.getUserCompanyDisable;
        }
    },
    watch: {
        selected(newValue) {
            this.$emit('optionChanged', newValue);
        },
        optionSelected(newValue) {
            this.selected = newValue;
        },
        vModel(newValue) {
            this.selected = newValue;
        }
    }
}
</script>

<style scoped lang="scss">
.custom-select__container {
    width: 100%;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;


}

.container__label {
    font-size: 14px;
    color: #707C7E;
    margin-bottom: 8px;
    text-align: left;
}

.custom-select {
    width: 100%;
    position: relative;
    display: inline-block;


}

.disable {
    opacity: .3;
    cursor: not-allowed;
}

select {
    min-width: 80px;
    cursor: pointer;
    width: 100%;
    height: 42px;
    border-radius: 7px;
    padding-left: 19px;
    padding-right: 35px;
    /* Espacio para la flecha */
    border: 1px solid #163f49;
    background: transparent;
    color: #0e0e0f;
    font-family: "interRegular", sans-serif;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background: url("../../assets/icons/angle-down.svg") no-repeat right 10px center;
    text-overflow: ellipsis;
}

.error {
    color: #C74D4D;
    font-size: 12px;
    margin-top: 5px;
    text-align: left;
}

select.error-select {
    border-color: #C74D4D;
}

select::-ms-expand {
    display: none;
}

.icon-container {
    position: absolute;
    top: 0;
    right: 12px;
    bottom: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
}


select:focus {
    outline: none;
}

/* Media queries */
@media (max-width: 768px) {
    select {
        font-size: 12px;
        height: 33px;
        padding-right: 30px;
        /* Ajuste para dispositivos móviles */
        background-size: 12px 6px;
    }
}
</style>
