<template>

    <div class="case-files">
        <div class="title-buttons">
            <generalTitle :title="titleName" :breadcrumbs="breadcrumbs" @goBack="this.goBack()" :root="root" />

            <div class="buttons">
                <GeneralButton v-if="!isSubCase && isPermited" href="/expedientes/estados"
                    :text="this.$t('caseFile.showCaseFile.buttons.manageStates')" :invertColor="true" />
                <GeneralButton :href="createbutton.to" :text="createbutton.text" />
            </div>
        </div>

        <InfoMessage :isActive="infoMessage.isActive" :color="infoMessage.icon === 'info' ? null : '#C74D4D'"
            :text="infoMessage.text" :icon="infoMessage.icon" />

        <!--Tabla de casos-->
        <div class="loader-div">
            <LoaderComponent v-if="isLoading" />
        </div>
        <div v-show="!isLoading && !infoMessage.isActive">
            <FileTableProgress :deleteModalInfo="deleteModalInfo" :dropdownData="dropdownData"
                @handleTriggerFetch="handleTriggerFetch" :columnConfig="tableColumns" :caseFiles="caseFiles"
                :contextMenu="true" :tableFilters="true" @toggleModalDelete="toggleModalDelete"
                :tableColumnsMovile="tableColumnsMovile" :noElementsMessage="noElementsMessage" :totalState="totalState" />

            <!--Paginación-->
            <div class="pagination">
                <PaginationComponent ref="paginationComponent" @data-fetched="handleDataFetched"
                    :path="pathData.getData" :getPath="pathData.pathKey" />
            </div>
        </div>

        <DeleteModalTable v-if="isDeleteModalActive" option="simple" :lastSingularId="deleteId"
            @toggleModal="toggleModalDelete" :cleanSelectActive="false" @trigger-fetch="handleTriggerFetch"
            :path="deleteModalInfo.path" :deleteModalText="deleteModalInfo.deleteModalText" />
    </div>
</template>




<script>


import GeneralButton from "@/components/general/Button.vue";
import FileTableProgress from "@/components/dashboard/FileTableProgress.vue";
import PaginationComponent from "./PaginationComponent.vue";
import generalTitle from "./generalTitle.vue";
import LoaderComponent from "./LoaderComponent.vue";
import InfoMessage from "./InfoMessage.vue";
import DeleteModalTable from "../categories/DeleteModalTable.vue";
import { nanoid } from 'nanoid';



export default {
    name: "CaseFilesLayaout",
    components: {
        GeneralButton,
        FileTableProgress,
        PaginationComponent,
        generalTitle,
        LoaderComponent,
        InfoMessage,
        DeleteModalTable
    },
    data() {
        return {
            deleteId: '',
            isDeleteModalActive: false,
            isLoading: false,
            totalState: 0,
            currentRoute: "",
            tableColumns: {
                caseCode: this.$t('general.tableColumTitles.code'),
                subject: this.$t('general.tableColumTitles.subject'),
                category: this.$t('general.tableColumTitles.category'),
                priority: this.$t('general.tableColumTitles.priority'),
                createdAt: this.$t('general.tableColumTitles.dateCreated'),
                lastActivity: this.$t('general.tableColumTitles.lastActivity'),
                // subcase: "Exp. a subsanar",
                //finalComment: "Comentario final",
            },
            tableColumnsMovile: [
                //cada array representa una fila dentro del grid
                [{ title: this.$t('general.tableColumTitles.code'), element: 'caseCode', id: nanoid() }, { title: this.$t('general.tableColumTitles.date'), element: 'createdAt', id: nanoid() }],
                [{ class: 'category', title: this.$t('general.tableColumTitles.category'), element: 'category', id: nanoid() }, { title: this.$t('general.tableColumTitles.priority'), element: 'priority', id: nanoid() }],
                [{ title: this.$t('general.tableColumTitles.lastActivity'), element: 'lastActivity' }],
                [{ title: this.$t('general.tableColumTitles.finalComment'), element: 'finalComment', id: nanoid() }],
                [{ title: this.$t('general.tableColumTitles.parentCaseFile'), element: 'subcase', id: nanoid() }]
            ],

            caseFiles: [
                //FALTAN
                //code: "EX000001",
                // company: "Empresa ABC",
                // subcase: "Exp.1234",
                // finalComment: "Caso cerrado tras resolución judicial.", 


            ],
            infoMessage: {
                isActive: false,
                icon: 'error',
                text: this.$t('general.infoMessage.generalError'),
            },


            //TO DO: Dejar el user limit final
            usersLimit: 18,
        };
    },
    methods: {
        toggleModalDelete(id) {
            this.isDeleteModalActive = !this.isDeleteModalActive
            if (id) {
                this.deleteId = id
            }
        },
        handleDataFetched(data) {

            if (data === 'error') {
                // si se produce un error en el fetch se activa

                this.caseFiles = [];  // Se actualiza después de this.error
                this.infoMessage.isActive = true
            } else {
               
                this.totalState = data.totalState
                const dataFormated = data.result.map(item => {
                    return {
                        ...item,
                        category: item.category.name,
                        priority: this.changePriorityNumberForText(item.priority),
                        createdAt: this.dateFormated(item.createdAt),
                        lastActivity: this.dateFormated(item.lastActivity),

                    }
                })
                this.caseFiles = dataFormated;
            }
            this.isLoading = false
        },
        changePriorityNumberForText(number) {
            if (number === 1) {
                return this.$t('general.inputElements.priority.state.low')
            } else if (number === 2) {
                return this.$t('general.inputElements.priority.state.medium')
            } else {
                return this.$t('general.inputElements.priority.state.hight')
            }
        },
        dateFormated(dateData) {

            const date = new Date(dateData);
            let day = date.getUTCDate();
            if (day < 10) {
                day = '0' + day
            }
            let month = date.getUTCMonth() + 1;
            if (month < 10) {
                month = '0' + month
            }
            const year = date.getUTCFullYear();

            return `${day}/${month}/${year}`;


        },
        handleTriggerFetch() {
            this.$refs.paginationComponent.getData();
        },
        goBack() {
            window.history.back();
        }
    },

    watch: {
        /*$route(to) {
            this.currentRoute = to.name;
               
        }
              */
    },
    created() {
        this.isLoading = true
    },


    props: {
        titleName: {
            type: String,
            requied: true
        },
        createbutton: {
            type: Object,
            required: true
        },
        pathData: {
            type: Object,
            requied: true
        },
        isSubCase: {
            type: Boolean,
            requied: false,
            default: false
        },
        breadcrumbs: {
            type: Array,
            requied: true
        },
        root: {
            type: Boolean,
            required: false,
            default: true
        },
        dropdownData: {
            type: Function,
            requied: true
        },
        deleteModalInfo: {
            type: Object,
            requied: true
        },
        isPermited: {
            type: Boolean,
            default: true
        },
        noElementsMessage: String
    },


}

</script>

<style scoped lang="scss">
.loader-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.case-files {
    width: 100%;
    margin-bottom: 100px;

    .breadcrumb {
        margin-top: 29px;
    }

    .title-buttons {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
        margin-bottom: 25px;


        h1 {
            text-align: left;
            margin: 0;
        }

        .buttons {
            display: flex;
        }

        .buttons a:first-child {
            margin-right: 32px;

        }

        .buttons a {
            transition: all .2s ease-in;
        }

    }

    .pagination {
        margin-top: 14px;
        padding-right: 19px;
    }
}



@media (max-width: 860px) {
    .case-files {
        .title-buttons {
            flex-wrap: wrap;

            .buttons {
                margin-top: 32px;
                width: 100%;
                display: flex;
            }
        }
    }
}

@media (max-width: 768px) {
    .case-files {
        .pagination {
            display: none;
        }

        .title-buttons {
            .buttons {
                flex-wrap: wrap;
                gap: 15px 32px;


                a {
                    margin-right: 0;
                    font-size: 14px;

                    @media (max-width: 564px) {
                        width: 100% !important;
                    }

                }

                a:first-child {
                    margin-right: 0;

                }
            }
        }
    }
}
</style>