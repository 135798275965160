<template>
    <div class="companies-list">
        <div class="companies-list__header">
            <generalTitle :title="this.$t('company.showCompanies.title')" :breadcrumbs="this.breadcrumbsList" :root="true" />
            <RouterLink class="create-company" to="/companies/create-company">{{ this.$t('company.showCompanies.button') }}</RouterLink>
        </div>
        <div class="loader-div">
            <LoaderComponent v-if="this.loading"></LoaderComponent>
        </div>
        <div class="error">
            <InfoMessage v-if="this.isError && !this.loading"
                :text="this.$t('general.infoMessage.generalError')" icon="error" :isActive="true"
                color="#C74D4D" />
        </div>
        <div v-if="!isError && !loading" class="companies-info">
            <div class="companies-list__search">
                <InputSearch @search="this.handleSearch($event)"  />
            </div>

            <div class="companies"
                :class="{ 'not-found': !this.loading && this.companies.length === 0, 'charging': this.loading }">
                <InfoMessage v-if="infoMessaActive" :text="infoMessageText" icon="info" :isActive="true" />

                <CompanyBox v-for="(company, index) in this.companies" :key="index" :identifier="company.id"
                    :companyCode="company.brand" :fullName="company.fullName" :departments="company.departmentsNumber"
                    :language="company.country" :logo="logo" @deletedCompany="this.deleteCompany($event)" />
            </div>
            <div class="pagination">
                <PaginationComponent :previousPage="this.pagination.previousPage"
                    :currentPage="this.pagination.currentPage" :nextPage="this.pagination.nextPage"
                    :lastPage="this.pagination.lastPage" @change-page="this.handleChangePage($event)"
                    :usersLimit="parseInt(this.limit)" @change-limit="this.limit = $event" />
            </div>
        </div>
    </div>
    <DeleteModalTable v-if="this.showDeleteModal" :option="'simple'" :deleteModalText="{
        textSingular: this.deleteModalTextTranslate,
        errorSingular: this.$t('company.showCompanies.deleteModal.errorSingular')
    }" :lastSingularId="this.selectedCompanyForDelete" :path="'/companies'"
        @toggleModal="this.showDeleteModal = !this.showDeleteModal" @clean-selected-categories="this.cleanCompanies()"
        />

</template>


<script>
import CompanyBox from '@/components/empresa/CompanyBox.vue';
import Logo from "@/assets/logos/Isotipo - Color.svg";
import PaginationComponent from '@/components/forms/Pagination.vue';
import InputSearch from '@/components/forms/InputSearch.vue';
import axios from 'axios';
import generalTitle from '@/components/general/generalTitle.vue';
import LoaderComponent from '@/components/general/LoaderComponent.vue';
import DeleteModalTable from '@/components/categories/DeleteModalTable.vue';
import InfoMessage from '../general/InfoMessage.vue';
export default {
    name: 'CompaniesComponent',
    components: {
        CompanyBox,
        PaginationComponent,
        InputSearch,
        generalTitle,
        LoaderComponent,
        DeleteModalTable,
        InfoMessage,

    },
    data() {
        return {
            logo: Logo,
            infoMessageText: '',
            infoMessaActive: false,
            isError: false,
            pagination: {
                previousPage: 0,
                currentPage: 1,
                nextPage: 0,
                lastPage: 1
            },
            limit: 25,
            companies: [],
            companySearch: '',
            breadcrumbsList: [
                {
                    label: this.$t('company.showCompanies.breadcrumbs'),
                    to: '/companies'
                }
            ],
            loading: false,
            showDeleteModal: false,
            deleteModalText: {
                textSingular: this.$t('company.showCompanies.deleteModal.textSingular'),
            },
            selectedCompanyForDelete: ''
        }
    },
    created() {
        this.loading = true;
        this.getCompanies();
        if (this.$store.getters.getCompany !== null) {
            this.$store.dispatch('setCompany', null);
        }
        if (this.$store.getters.getDepartments.length > 0) {
            this.$store.dispatch('setDepartments', []);
        }
    },
    methods: {
        goBack() {
            window.history.back();
        },
        getCompanies() {
            this.isError = false
            this.infoMessaActive = false
            this.isAnyCompanyCreated = false
            this.companies = [];
            axios.get(`/companies?itemsPerPage=${this.limit}&page=${this.pagination.currentPage}${this.companySearch !== '' ? '&search=' + this.companySearch : ''}`)
                .then(response => {
                    this.loading = false;
                    this.companies = response.data['hydra:member'];
                    var host = window.location.protocol + "//" + window.location.host;
                    if (response.data['hydra:view']['hydra:last'] !== undefined) {
                        this.pagination.lastPage = Number((new URL(host + response.data['hydra:view']['hydra:last'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:next'] !== undefined) {
                        this.pagination.nextPage = Number((new URL(host + response.data['hydra:view']['hydra:next'])).searchParams.get('page'));
                    }
                    if (response.data['hydra:view']['hydra:previous'] !== undefined) {
                        this.pagination.previousPage = Number((new URL(host + response.data['hydra:view']['hydra:previous'])).searchParams.get('page'));
                    }
                    // Si la página actual es mayor que la última página, se establece la última página como página actual
                    if (this.pagination.currentPage > this.pagination.lastPage && this.pagination.lastPage > 0) {
                        this.pagination.currentPage = this.pagination.lastPage;
                    }
                    // En caso de busqueda exacta no devuelve la paginación
                    if (response.data['hydra:view']['hydra:last'] === undefined) {
                        this.pagination.currentPage = 1;
                        this.pagination.lastPage = 1;
                        this.pagination.previousPage = 0;
                        this.pagination.nextPage = 0;
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.isError = true
                })
                .finally(() => {
                    this.loading = false;

                    //si hay error no tenemos que chequear si existen empresa o se buscan
                    if (this.isError) return
                    // no se ha creado ninguna empresa
                    if (this.companies.length === 0 && !this.companySearch) {
                        this.infoMessaActive = true
                        this.infoMessageText = this.$t('company.showCompanies.noCompanies')
                    }
                    // no se ha encontrado ninguna empresa en el buscador
                    else if (this.companies.length === 0 && this.companySearch) {
                        this.infoMessaActive = true
                        this.infoMessageText = this.$t('general.search.noCompany')
                    }
                })
        },
        handleSearch(search) {
            if (typeof search === 'string') {
                this.companySearch = search;
            }
        },
        deleteCompany(data) {
            this.selectedCompanyForDelete = data;
            this.showDeleteModal = true;
        },
        cleanCompanies() {
            if (this.selectedCompanyForDelete === this.$store.getters.getUserCompany) {
                this.$store.dispatch('setUserCompanyAction', this.companies[0].id);
            }
            this.getCompanies();
            this.selectedCompanyForDelete = '';
        },
        handleChangePage(page) {
            this.pagination.currentPage = page;
            this.pagination.previousPage = page - 1;
            this.pagination.nextPage = page + 1;
        },
    },
    computed: {
        breadcrumb() {
            return this.$t('company.showCompanies.breadcrumbs');
        },
        deleteModalTextTranslate() {
            return this.$t('company.showCompanies.deleteModal.textSingular');
        }
    },
    watch: {
        companySearch: function () {
            this.getCompanies();
        },
        limit: {
            handler() {
                this.getCompanies();
            }
        },
        'pagination.currentPage': function () {
            this.getCompanies();
        },
        breadcrumb: {
            handler(newVal) {
                this.breadcrumbsList[0].label = newVal;
            }
        },
    },
}
</script>

<style scoped lang="scss">
.error {
    width: 100%;
}

.loader-div {
    width: 100%;
    display: flex;
    justify-content: center;

}

.companies-list,
.companies-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;

}




.companies-list {

    .companies-list__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .create-company {
            width: 283px;
            height: 32px;
            background: #163F49;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }
    }

    .companies-list__search {
        width: 100%;

    }

    .companies {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-auto-rows: minmax(auto, 144px);
        gap: 24px;
        width: 100%;
        max-height: 700px;
        min-height: 700px;
        overflow-y: auto;

        .company-box {
            width: 100%;
        }
    }

    .companies.not-found {
        justify-content: flex-start;
        align-items: flex-start;

        .alert {
            grid-area: 1 / 1 / 2 / 3;
        }
    }

    .companies.charging {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination {
        width: 100%;
    }
}

@media (max-width:1200px) {
    .companies-list {

        .companies {
            max-height: none;
            flex-direction: column;
            flex-wrap: nowrap;
            height: calc(100dvh - 380px);

            .company-box {
                width: 100%;
            }
        }
    }

    .companies-info {
        max-width: 100%;
    }
}

@media (max-width:1024px) {
    .companies-list {
        .companies {
            display: flex;
            flex-direction: column;
        }
    }

}

@media (max-width:768px) {
    .companies-list {

        .companies-list__search {
            width: 100%;

            &>div {
                max-width: 100% !important;
            }

        }

        .companies-list__title {
            display: none;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 24px;
            width: 100%;

            .title__text {
                margin: 0;
                font-size: 24px;
                font-family: 'InterSemibold';
                color: #163F49;
            }

            .title__icon {
                width: 8px;
            }
        }

        .create-company {
            width: 100%;
        }

        .companies {

            gap: 16px;
            height: calc(100dvh - 390px);

        }
    }
}

@media (max-width:569px) {
    .companies-list {
        .companies-list__header {
            flex-direction: column;
            gap: 16px;

            .create-company {
                width: 100%;
            }
        }

        .companies {
            min-height: 500px;
        }
    }
}
</style>