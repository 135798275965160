<template>

    <Pagination v-if="isPagination" :previousPage="this.pagination.previousPage"
        :currentPage="this.pagination.currentPage" :nextPage="this.pagination.nextPage"
        :lastPage="this.pagination.lastPage" :usersLimit="parseInt(this.elementLimit)"
        @change-limit="this.elementLimit = $event" @change-page="this.handleChangePage($event)" />

</template>

<script>

import Pagination from '../forms/Pagination.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import {translationStateDefaultMixing, translationCategoryDefaultMixing} from "@/components/translationDefaultMixing.js"


export default {
    name: 'PaginationComponent',
    components: { Pagination },
    props: {
        searchValue: {
            type: String,
            default: ''
        },
        path: {
            type: String,
            require: true
        },
        order: {
            type: String,
            default: 'DESC'
        },
        orderAttr: {
            type: String,
            default: 'id'
        },
        getPath: {
            type: String,
            required: false
        }

    },
    data() {
        return {
            search: '',
            isPagination: false,
            results: [],
            pagination: {
                previousPage: 0,
                currentPage: 1,
                nextPage: 1,
                lastPage: 0
            },
            elementLimit: 25,
            totalElemnts: 0,
            isFirstime: true
        }
    },
    mixins: [translationStateDefaultMixing, translationCategoryDefaultMixing],
    computed: {
        ...mapGetters(['getUserCompany']),

        userCompany() {
            return this.getUserCompany;
        }
    },
    watch: {
        searchValue(newvalue) {
            this.search = newvalue
            this.getData()
        },
        order() {
            this.getData()
        },
        orderAttr() {
            this.getData()
        },
        elementLimit: {
            handler() {
                this.getData();
            }
        },
        'pagination.currentPage': function () {
            this.getData();
        },
        userCompany(newValue) {
            if (newValue === '') {
                //significa que hemos hecho logout
                return
            }

            this.getData()
        },
        //DE MOMENTO mientras no tengo el path de subcasefile preparado
        path() {
            this.getData()
        }
    },
    created() {
        this.getData();
    },

    methods: {
        getData() {
            if (!this.path) return
            let url = ''
            if (this.getPath === 'state') {
                url = `${this.path}?itemsPerPage=${this.elementLimit}&page=${this.pagination.currentPage}&company=${this.userCompany}&order[${this.orderAttr}]=${this.order}`
            } else if (this.getPath === 'files') {
                url = `${this.path}?itemsPerPage=${this.elementLimit}&page=${this.pagination.currentPage}&company=${this.userCompany}&exclude_subcasefile=true&search=${this.search}&order[${this.orderAttr}]=${this.order}`
            }
            //necesito tener subcase por id para poder crear el breadcrum y cambiar la parte  del alfter axios.get(url) linea 141
            else if (this.getPath === 'subCaseFile') {
                url = `${this.path}`
            }
            else {
                url = `${this.path}?itemsPerPage=${this.elementLimit}&page=${this.pagination.currentPage}&company=${this.userCompany}&search=${this.search}&order[${this.orderAttr}]=${this.order}`
            }

            axios.get(url)
                .then(response => {
                    if (this.getPath === 'subCaseFile') {
                        this.results = response.data.subCaseFiles
                    } else {

                        this.results = response.data["hydra:member"]
                        this.totalElemnts = response.data['hydra:view'];
                        //Se necesita el host para obtener la url completa
                        var host = window.location.protocol + "//" + window.location.host;
                        // Se obtienen los datos de la paginación
                        if (response.data['hydra:view']['hydra:last'] !== undefined) {
                            this.pagination.lastPage = Number((new URL(host + response.data['hydra:view']['hydra:last'])).searchParams.get('page'));
                        }
                        if (response.data['hydra:view']['hydra:next'] !== undefined) {
                            this.pagination.nextPage = Number((new URL(host + response.data['hydra:view']['hydra:next'])).searchParams.get('page'));
                        }
                        if (response.data['hydra:view']['hydra:previous'] !== undefined) {
                            this.pagination.previousPage = Number((new URL(host + response.data['hydra:view']['hydra:previous'])).searchParams.get('page'));
                        }
                        // Si la página actual es mayor que la última página, se establece la última página como página actual
                        if (this.pagination.currentPage > this.pagination.lastPage && this.pagination.lastPage > 0) {
                            this.pagination.currentPage = this.pagination.lastPage;
                        }
                        // En caso de busqueda exacta no devuelve la paginación
                        if (response.data['hydra:view']['hydra:last'] === undefined) {
                            this.pagination.currentPage = 1;
                            this.pagination.lastPage = 1;
                            this.pagination.previousPage = 0;
                            this.pagination.nextPage = 0;
                        }
                        this.isPagination = true

                        if(this.path === '/categories' ) {
                            //necesito descripción    
                            this.results = this.changeTraslationDefaultCategories(this.results)
                        }

                        if(this.getPath === 'subCaseFile'|| this.getPath === 'files') {
                            //porque de momento no tengo la descripcion de las categorias como parametro
                            this.results = this.results.map(element => ({...element, category: this.changeTraslationDefaultCategories([element.category], false) }))
                            .map(element => ({...element, category: element.category[0]}))
                        }
                       
                        if(this.getPath === 'state') {
                            //modificamos la traduccion de los estados por defecto
                            this.results = this.changeTraslationDefaultState(this.results)
                        }
                    }

                    

                })
                .then(() => {
                    if(this.isFirstime && (this.getPath === 'files' || this.getPath === 'subCaseFile')) {
                        return axios.get(`/states?company=${this.userCompany}`)
                    }
                })
                .then((response) => {
                    if(response) {

                        //si requiere de la cantidad total de estados
                        this.$emit('data-fetched', {result: this.results, totalState: response.data['hydra:totalItems']});
                    } else{
                        this.$emit('data-fetched', this.results);
                    }
                  
                 
                })
                .catch(error => {
                    console.log(error);
                    this.results = [];
                    this.$emit('data-fetched', 'error');
                });
        },
        handleChangePage(page) {
            this.pagination.currentPage = page;
            this.pagination.previousPage = page - 1;
            this.pagination.nextPage = page + 1;
        },

    },
}


</script>


<style scoped></style>