<template>

    <template v-if="!isInput">
        <select :title="localSelectedValue" :disabled="isDisable" :class="isDisable && 'disabled'"
            v-model="localSelectedValue" @change="updateSelectedValue" :id="labelIndentify">
            <option disabled value="" selected hidden>{{ placeholderText }}</option>
            <option v-for="option in optionArray" :key="option.label" :value="option.label" class="option">
                {{ option.label }}
            </option>
        </select>
        <p class="error-message" v-if="error && selectedValue === ''">{{ this.$t('general.infoMessage.fillElement') }}</p>
    </template>


    <div v-else>
        <input :title="localSelectedValue" v-model="localSelectedValue" :disabled="isDisable" :id="labelIndentify" type="text"
            :class="{ 'has-placeholder': selectedValue === '', 'error': error && selectedValue === '' }"
            :placeholder="placeholderText" @input="updateSelectedValue">
        <p class="error-message" v-if="error && selectedValue === ''">{{ this.$t('general.infoMessage.fillElement') }}</p>
    </div>

</template>


<script>

export default {
    name: 'SelectComponent',
    data() {
        return {
            localSelectedValue: this.selectedValue
        };
    },
    props: {
        labelIndentify: {
            type: String
        },
        placeholderText: {
            type: String,
        },
        optionArray: {
            type: Array,
            required: false
        },
        selectedValue: {
            type: String
        },
        // Esto es para que cuando pasemos un select en el que las opciones son siempre las mismas, 
        //le aplicamos unos estilos para que no aparezca el titulo al seleccionarlas
        isDinamic: {
            type: Boolean,
            required: false,
            default: true
        },
        error: {
            type: Boolean
        },
        fileDataName: {
            type: String
        },
        // para seleccionar el select o el input
        isInput: {
            type: Boolean,
            default: false
        },
        isDisable: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        updateSelectedValue(event) {
            this.$emit('update:selectedValue', event.target.value);
        }
    },
    emits: ['cleanFileDataError', 'update:selectedValue'],
    watch: {
        selectedValue() {
            this.localSelectedValue = this.selectedValue
        },
        localSelectedValue(newv) {
            if (this.error && newv !== '') {
                //emit de clean el error
                this.$emit('cleanFileDataError', this.fileDataName)

            }
        },

    },
}


</script>

<style scoped lang="scss">
.error {
    border-color: #C74D4D;
}

.disabled {

    cursor: not-allowed !important;
}



.error-message {
    text-align: left;
    color: #C74D4D;
    padding-top: 6px;
    font-size: 14px;
    font-family: 'InterRegular';
}

input {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1.25px solid #B3BBBC;
    font-size: 14px;
    color: #0E0E0F;
    font-family: 'InterRegular';
    line-height: 16.94px;
    padding-left: 14px;


    &::placeholder {
        color: #B3BBBC;
        font-size: 14px;
        font-family: 'InterRegular';
    }
}

input {
    cursor: pointer;
}



select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('@/assets/icons/angle-down.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;

    cursor: pointer;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 1.25px solid #B3BBBC;
    font-size: 14px;
    color: #0E0E0F;
    font-family: 'InterRegular';
    line-height: 16.94px;
    padding-left: 14px;
    padding-right: 32px;

    @media (max-width: 600px) {
        font-size: 12px;
    }


    &::placeholder {
        color: #B3BBBC;
        font-size: 14px;
        font-family: 'InterRegular';
    }

    .option {
        width: 30%;
    }
}


option {
    color: #0E0E0F;

}

.has-placeholder {
    color: #B3BBBC;
}
</style>